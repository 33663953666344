@font-face {
  font-family: "Moon2.0-Regular";
  src: url(./Moon2.0-Regular.otf); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Moon2.0-Light";
  src: url(./Moon2.0-Light.otf); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Moon2.0-bold";
  src: url(./Moon2.0-Bold.otf); /* IE9 Compat Modes */
}
@font-face {
  font-family: "._Noah-Regular";
  src: url(./Noah-Regular.eot); /* IE9 Compat Modes */
  src: url(./Noah-Regular.woff2) format("woff2"), /* Super Modern Browsers */ url(./Noah-Regular.woff) format("woff"),
    /* Pretty Modern Browsers */ url(./Noah-Regular.ttf) format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

/*
    &.ru {}
    &.kr {}
    &.ch {}
    &.jp {}
*/
$moon: "Moon2.0-Regular", "Moon", sans-serif;
$moonLight: "Moon2.0-Light", "MoonLight", sans-serif;
$moonBold: "Moon2.0-Bold", "MoonBold", sans-serif;
$rob: "Roboto", sans-serif;
$noah: "._Noah-Regular", "noah", sans-serif;
$quicksand: "Quicksand", sans-serif;

$blue: #162d4c;
$blue2: #020202;
$purp: #5d2f58;
$lightpurp: #d4c3ed;
$backupBlue: #242130;
$explainBlue: #9ff5ff;
$igray: #f6f6f6;
$igray2: #c3b8b8;
$agray: #eeeced;
$offWhite: #f7f7f7;
$pilloutline: #7180a3;
$buttonblue: #4592f8;
$gDark: #344853;
$aPurpz: #4a297e;
$dBlack: #5a585a;
$aNewDk: #232338;

$sun: #fad155;
$ocean: #54b3e4;
$astral: #e0a5ff;
$death: #989fa7;
$life: #75c26a;

$bellytext: #7da9e3;

%no-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
%no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}

* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
#logo {
  float: left;
  margin-top: 15px;
  @extend %no-drag;
  img {
    width: 370px;
    @extend %no-drag;
  }
  a {
    @extend %no-drag;
  }
}
.circle-button {
  // extended later on
  border: 2.5px solid white;
  height: 35px;
  border-radius: 21px;
  cursor: pointer;
  font-family: $moon;
  color: white;
  letter-spacing: 3px;
  line-height: 34px;
  font-size: 13px;
  word-spacing: -6px;
  &:hover {
    background-color: $blue;
  }
}
#menu-right {
  float: right;
  margin-top: 36px;
  position: relative;
}

#lang-drop,
#lang-drop2 {
  z-index: 999;
  float: right;
  height: 46px;
  width: 40px;
  border-radius: 35px;
  position: absolute;
  right: -5px;
  top: -3px;
  overflow: hidden;
  &.isActive {
    // background-color: #f7f7f7;
    // min-height: 179px;
    // height: auto;
    animation-timing-function: linear;
    animation: mymove 0.5s 1;
    animation-fill-mode: forwards;
  }
  &.main {
    top: 0;
  }
  img {
    @extend %no-drag;
    cursor: pointer;
    margin-left: 4px;
    margin-right: auto;
    margin-bottom: -1px;
    margin-top: 7px;
    height: 33px;
    width: 33px;
  }
}

@keyframes mymove {
  from {
    background-color: rgba(255, 255, 255, 0);
    height: 46px;
  }
  to {
    background-color: rgba(255, 255, 255, 1);
    height: 221px;
  }
}
#lang-drop2 {
  img {
    margin-left: 1px;
  }
}
#navigation {
  display: inline-block;
  width: 100%;
}
.mask,
.mask2,
.mask3 {
  width: 115px;
  float: right;
  // margin-right: 60px;
  @extend .circle-button;
  text-align: center;
  .mask-inner {
    text-transform: uppercase;
    // margin-left: 18px;
    display: inline-block;
  }
  img {
    float: right;
    margin-top: 8px;
    margin-right: 17px;
  }
  &.ru {
    width: 196px;
  }
  &.kr {
    font-size: 21px;
    width: 105px;
  }
  &.ch {
    font-size: 21px;
    width: 107px;
  }
  &.jp {
    font-size: 15px;
    width: auto !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  &.dark-menu-life {
    color: #329854 !important;
    border-color: #329854 !important;
    &:hover {
      color: white !important;
      border-color: white !important;
    }
  }
}

.mask2,
.mask3 {
  width: 105px;
  margin-right: 20px;
  .mask-inner {
    display: inline-block;
    text-transform: uppercase;
  }
  &.ru {
    width: 138px;
  }
  &.kr {
    width: 81px;
  }
  &.ch {
    width: 81px;
  }
  &.jp {
    width: 81px;
    width: 175px;
  }
}
.mask3 {
  // margin-right: 60px;

  .mask-inner {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 0px;
  }
}
.jumbo,
.jumbo2 {
  &.allowoverflow {
    overflow: initial !important;
  }
  height: 730px;
  background-image: url("https://woe2.s3.amazonaws.com/gradient-starless.jpg");
  background-size: cover;
  background-color: $backupBlue;
  overflow: hidden;
  &.smalljumbo {
    height: 110px;
    background-image: url("https://woe2.s3.amazonaws.com/DarkGradient.png");
  }
  .hidden {
    display: none;
  }
  .button,
  .button-alt {
    float: left;
    margin-top: 30px;
    @extend .circle-button;
    width: 195px;
    img {
      float: right;
      margin-top: 9px;
      margin-right: 13px;
    }
  }
  .button-alt {
    width: 190px;
    margin-left: 20px;
    font-family: $moonBold;
    background-color: white;
    color: $purp;
    img {
      float: right;
      margin-top: 9px;
      margin-right: 14px;
      height: 17px;
    }
    &:hover {
      color: white;
    }
  }
  .square-button {
    margin-top: 110px;
    background-color: $blue;
    color: white;
    border: 2px solid white;
    height: 35px;
    line-height: 35px;
    width: 400px;
    font-family: $noah;
    font-size: 17px;
    letter-spacing: 2px;
    word-spacing: 2px;
  }
  img.monInside {
    position: absolute;
    left: 0px;
    z-index: 999;
  }
}
.jumbump {
  height: 760px;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.thin-wrap {
  width: 1000px;
  margin: 0 auto;
}
#main-sect {
  margin-top: 75px;
}
.jumbo2 {
  height: 1000px;
}
#jumboimgz {
  position: relative;
  #shipz {
    left: calc(50% + 120px);
    position: absolute;
    z-index: 2;
    height: 770px;
  }
  #moonz {
    position: absolute;
    z-index: 2;
    top: -92px;
    width: 550px;
    left: calc(50% - 605px);
  }
  &.altJumb {
    overflow: hidden;
    width: 100vw;
    #shipz {
      top: initial;
      bottom: -90px;
      z-index: 0;
      overflow: hidden;
      opacity: 0.5;
    }
    #moonz {
      top: initial;
      bottom: -90px;
      z-index: 0;
      overflow: hidden;
      opacity: 0.5;
    }
  }
}
.home-mint-us,
.home-join-us {
  font-size: 20px;
  line-height: 32px;
  z-index: 999;
  position: absolute;
  text-align: center;
  font-family: $quicksand;
  color: white;
  width: 689px;
  left: 50%;
  transform: translateX(-50%);
}
.romanbutton {
  background-color: $blue;
  height: 46px;
  width: 270px;
  color: white;
  font-family: $moonBold;
  border-radius: 25px;
  line-height: 45px;
  letter-spacing: 3px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  margin-top: 40px;
  border: 2.5px solid transparent;
  &:hover {
    background-color: white;
    color: $blue;
    border: 2.5px solid black;
  }
}
#cynImg {
  width: 100%;
  height: 685px;
  position: relative;
  .cynImgHold {
    background-image: url("https://woe2.s3.amazonaws.com/battleeth-min.png");
    background-size: cover;
    background-position: center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    h1 {
      color: white;
      margin-top: 260px;
      font-size: 31px;
    }
    p {
      font-family: $quicksand;
      width: 675px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      color: white;
      margin-top: 50px;
      line-height: 32px;
      font-size: 20px;
    }
  }
}
#adhdsec {
  height: 700px;
  #bigOne {
    color: $dBlack;
    font-family: $moonBold;
    font-size: 24px;
    text-align: center;
    margin-top: 70px;
  }
  span.adot {
    letter-spacing: 5px;
  }
  span.acomma {
    font-family: $quicksand;
    font-size: 39px;
  }
  .subBigSect {
    margin-top: 35px;
    width: 540px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: $quicksand;
    line-height: 32px;
    color: $aNewDk;
    font-size: 20px;
  }
}
#royalMid {
  background: rgb(128, 94, 181);
  background: linear-gradient(137deg, rgba(128, 94, 181, 1) 22%, rgba(255, 255, 255, 1) 100%);
  height: 670px;
}
#royalMidContain {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  color: white;
  position: relative;
  .lf-side {
    height: 29px;
    width: 35%;
    margin-left: 20px;
    margin-top: 120px;
    // float: left;
    position: absolute;
    .romanbutton {
      background-color: $aPurpz;
      margin-right: initial;
      margin-left: initial;
      margin-top: 70px;
      &:hover {
        background-color: white;
        border-color: $aPurpz;
      }
    }
  }
  .tp-side {
    font-family: $moonBold;
    letter-spacing: 6px;
    font-size: 22px;
  }
  .md-side {
    margin-top: 50px;
    font-family: $quicksand;
    font-size: 18px;
    line-height: 26px;
  }
  .rt-side {
    width: 50%;
    left: 45%;
    position: absolute;
    top: 103px;
    img.wall-img {
      animation: wallShake 4s infinite alternate-reverse;
    }
    img.crys-img {
      animation: wallShake 10s infinite alternate-reverse;
      position: absolute;
      top: -21px;
      right: -49px;
    }
  }
}
@keyframes wallShake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
#romanMid {
  background-color: #f0f2f3;
  min-height: 555px;
  margin-top: 160px;
  #romanTop {
    color: $aNewDk;
    font-size: 18px;
    text-align: center;
    font-family: $quicksand;
    width: 580px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    line-height: 26px;
  }

  #someImgHold {
    width: 690px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .singlez:nth-of-type(1n) {
      height: 120px;
    }
    .singlez:nth-of-type(2n) {
      height: 150px;
    }
    .singlez:nth-of-type(3n) {
      height: 160px;
      position: relative;
      top: -7px;
    }
    .singlez:nth-of-type(4n) {
      height: 210px;
      position: relative;
      top: 2px;
    }
  }
}
.home-join-us {
  margin-top: 188px;
  &.ch {
    // font-size: 39px;
    font-size: 24px;
    line-height: 40px;
  }
  &.jp {
    font-size: 21px;
  }
  &.kr {
    font-size: 21px;

    // font-size: 39px;
  }
}
.home-mint-us {
  margin-top: 310px;
}
.new-site-title {
  z-index: 999;
  position: absolute;
  text-align: center;
  font-family: $moon;
  letter-spacing: 6px;
  word-spacing: -11px;
  color: white;
  font-size: 32px;
  line-height: 45px;
  margin-top: 98px;
  left: 50%;
  transform: translateX(-50%);
  &.ch {
    font-size: 39px;
  }
  // &.jp {
  // }
  &.kr {
    font-size: 39px;
  }
}
.site-title {
  font-family: $moonLight;
  letter-spacing: 6px;
  word-spacing: -11px;
  color: white;
  font-size: 38px;
  line-height: 45px;
  width: 85%;
  text-align: left;
  display: inline;
  float: left;
  &.ch {
    font-size: 62px;
    line-height: 97px;
  }
  &.jp {
    font-size: 62px;
    line-height: 97px;
  }
  &.kr {
    font-size: 62px;
    line-height: 72px;
  }
}
.site-desc {
  text-align: left;
  margin-top: 170px;
  font-family: $moonLight;
  letter-spacing: 1.5px;
  word-spacing: -6px;
  color: white;
  line-height: 21px;
  width: 85%;
  font-size: 15px;
  span {
    font-family: $moonBold;
  }
}
.relativecontain {
  height: 480px;
  position: relative;
  width: 370px;
  margin-left: auto;
  margin-right: auto;
}
img.gifback {
  z-index: 999;
  width: 370px;
  border-radius: 38px;
}
p.sub-img-text {
  font-family: $moonLight;
  letter-spacing: 1.4px;
  word-spacing: -6px;
  font-size: 15px;
  color: white;
  span {
    font-family: $moonBold;
  }
}
#homegrid.grid {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-gap: 1em;
  .cell-t {
    position: relative;
    .button.ch {
      font-size: 22px;
      width: 162px;
    }
    .button-alt.ch {
      font-size: 22px;
      width: 162px;
    }
    .button.kr {
      font-size: 18px;
    }
    .button-alt.kr {
      font-size: 18px;
    }
    .button.jp {
      font-size: 18px;
    }
    .button-alt.jp {
      font-size: 18px;
    }
    &.cell-t-1 {
      grid-row-start: 1fr;
    }
    text-align: center;
  }
}
.starlight {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}
img.e-041 {
  left: 5px !important;
  top: 9px !important;
}
img.e-033 {
  left: 12px !important;
  top: 29px !important;
}
img.e-008 {
  top: 36px !important;
  left: 13px !important;
}
// img.e-012 {
// }
img.e-017 {
  left: 12px !important;
  top: 29px !important;
}
img.e-039 {
  left: 12px !important;
  top: 34px !important;
}
img.e-049 {
  left: 10px !important;
  top: 26px !important;
}
img.e-051 {
  top: 26px !important;
  left: 10px !important;
}
img.e-054 {
  top: 30px !important;
  left: 12px !important;
}
img.e-063 {
  left: 30px !important;
  top: 39px !important;
}
img.e-073 {
  transform: rotate(3deg);
  left: 19px !important;
  top: 26px !important;
}
img.e-076 {
  left: 16px !important;
  top: 41px !important;
}
img.e-081 {
  top: 28px !important;
}
img.e-082 {
  left: 18px !important;
  top: 25px !important;
}
img.e-084 {
  left: 12px !important;
  top: 5px !important;
}
img.e-089 {
  top: 15px !important;
}
img.e-096 {
  top: 27px !important;
}
img.e-100 {
  top: 30px !important;
  left: 12px !important;
}
img.e-105 {
  top: 22px !important;
  left: 12px !important;
}
img.e-107 {
  top: 9px !important;
  left: 12px !important;
}
img.e-117 {
  top: 33px !important;
  left: 16px !important;
}
// img.e-121 {
// }
img.e-122 {
  top: 40px !important;
}
img.e-123 {
  left: 13px !important;
  top: 21px !important;
}
// img.e-125 {
// }
// img.e-142 {
// }
img.e-147 {
  top: 47px !important;
  left: 17px !important;
}
img.e-157 {
  top: 32px !important;
  left: 17px !important;
}
img.e-161 {
  top: 29px !important;
}
img.e-162 {
  top: 27px !important;
}
// img.e-179 {
// }
img.e-189 {
  top: 32px !important;
  left: 17px !important;
}
img.e-199 {
  top: 39px !important;
}
img.e-194 {
  top: 32px !important;
  left: 17px !important;
}
.viewbutton {
  font-family: $moon;
  letter-spacing: 1px;
  word-spacing: 1px;
  background-color: $blue;
  color: white;
  height: 33px;
  width: 266px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  border: 2px solid $blue;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  &:hover {
    background-color: white;
    color: $blue;
  }
  &.butedit_1 {
    margin-top: 190px;

    width: 150px;
  }
  &.ru {
    font-size: 18px;
    line-height: 28px;
  }
  &.kr {
    font-size: 18px;
    line-height: 28px;
  }
  &.ch {
    font-size: 18px;
    line-height: 28px;
  }
  &.jp {
    font-size: 18px;
    line-height: 28px;
  }
}
.viewbutton {
  &.nomatch {
    &.ru {
      line-height: 31px;
    }
    &.kr {
      line-height: 31px;
    }
    &.ch {
      line-height: 31px;
    }
    &.jp {
      line-height: 31px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
img.joindisc {
  width: 300px;
  float: left;
  margin-top: 54px;
  cursor: pointer;
}
img.joindisc:hover {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.199));
  filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.106));
  animation: shake 0.5s;
  animation-iteration-count: once;
}
img.joindisc2 {
  position: absolute;
  margin-top: 400px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes shake {
  0% {
    transform: translateX(-51%) rotate(0deg);
  }
  10% {
    transform: translateX(-49%) rotate(0deg);
  }
  20% {
    transform: translateX(-48%) rotate(0deg);
  }
  30% {
    transform: translateX(-52%) rotate(0deg);
  }
  40% {
    transform: translateX(-51%) rotate(0deg);
  }
  50% {
    transform: translateX(-49%) rotate(0deg);
  }
  60% {
    transform: translateX(-48%) rotate(0deg);
  }
  70% {
    transform: translateX(-48%) rotate(0deg);
  }
  80% {
    transform: translateX(-49%) rotate(0deg);
  }
  90% {
    transform: translateX(-49%) rotate(0deg);
  }
  100% {
    transform: translateX(-49%) rotate(0deg);
  }
}
#centerFaq {
  background-color: $offWhite;
  min-height: 1000px;
  font-family: $noah;
  .containwrapp {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  #leftFaq {
    min-height: 100px;
    width: 50%;
    float: left;
  }
  #rightFaq {
    min-height: 100px;
    width: 50%;
    float: left;
    margin-top: 50px;
    .question {
      margin-bottom: 17px;
    }
    .answer {
      margin-bottom: 45px;
    }
  }
  .bubbleBox {
    background-color: $agray;
    min-height: 100px;
    width: 300px;
    padding: 20px 25px 40px 25px;
    border-radius: 16px;
    margin-top: 50px;
    p {
      line-height: 23px;
      font-size: 16px;
    }
    #biggah {
      font-size: 20px;
      margin-bottom: 12px;
      margin-top: 10px;
    }
  }
}
#bigztext {
  background-image: url("https://woe2.s3.amazonaws.com/LightGradient.jpg");
  background-size: cover;
  width: 100%;
  min-height: 900px;
  text-align: center;
  position: absolute;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
  .maintxtare {
    color: $lightpurp;
    font-size: 500px;
    font-family: $noah;
    opacity: 0.6;
    font-weight: bold;
  }
  img {
    width: 700px;
    margin-top: -624px;
    z-index: 999;
    position: relative;
  }
  .subtxtare {
    text-transform: uppercase;
    font-family: $moonLight;
    letter-spacing: 3px;
    word-spacing: 1px;
    font-size: 25px;
    margin-top: -10px;
  }
  .viewbutton {
    text-transform: uppercase;
    width: 0px;
    min-width: 181px;
  }
  &.faqEdit {
    z-index: -1;
    position: initial;
    min-height: initial;
    text-align: initial;
    background-position: 20% 30%;
    #hcenter {
      padding-top: 100px;
      font-family: $moonLight;
      letter-spacing: 3px;
      text-transform: uppercase;
      word-spacing: 1px;
    }
    #hsubcent {
      padding-bottom: 100px;
      margin-top: 15px;
      font-size: 19px;
      font-family: $noah;
      letter-spacing: 0px;
      word-spacing: 3px;
    }
  }
}
#midSection {
  margin-top: 65px;
  p.midinfo {
    font-family: $quicksand;
    color: $gDark;
    width: 555px;
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    // letter-spacing: 2px;
    // word-spacing: -1px;
    font-size: 20px;
    line-height: 32px;
  }
  h1 {
    font-family: $moonBold;
    letter-spacing: 6px;
    text-align: center;
    font-size: 24px;
    color: $gDark;
    &.specif {
      // &.ru {
      // }
      &.kr {
        font-size: 42px;
      }
      &.ch {
        font-size: 42px;
      }
      // &.jp {
      // }
    }
  }

  .midgrid {
    margin-top: 103px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
    .cell {
      position: relative;
      img {
        width: 100%;
        height: auto;
        border-radius: 17px;
      }
      .adjustmon {
        position: absolute;
        &.adj1 {
          top: -46px;
          width: 247px;
          left: 0px;
        }
        &.adj2 {
          top: 74px;
          width: 247px;
        }
        &.adj3 {
          width: 260px;
          top: -54px;
          left: -10px;
        }
        &.adj4 {
          width: 281px;
          top: 23px;
          left: -44px;
        }
        &.adj5 {
          width: 281px;
          top: -54px;
          left: -22px;
        }
      }
    }
  }
}
#flipped {
  padding-bottom: 130px;
  margin-top: 120px;
  background-image: url("https://woe2.s3.amazonaws.com/LightGradient.jpg");
  background-size: cover;
  min-height: 620px;
  width: 100%;
  text-align: center;
  background-position: right bottom -693px;
  position: relative;
  overflow: hidden;
  .contref {
    margin-top: 55px;
    a {
      font-family: $rob;
      color: $blue2;
      font-size: 16px;
    }
  }
  #socialfeet {
    img {
      margin-top: 100px;
      margin-left: 50px;
      margin-right: 50px;
      width: 85px;
      cursor: pointer;
    }
  }
  img.footerMon {
    opacity: 0.42;
    position: absolute;
    bottom: -77px;
    right: -176px;
    width: 540px;
  }
  #flycatch {
    width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  #bluegrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2em;
    grid-column-gap: 1.5em;
    margin-top: 100px;
    a {
      text-decoration: none;
    }
    .bluecap {
      font-family: $rob;
      color: white;
      line-height: 50px;
      background-color: $blue;
      height: 48px;
      border: 2px solid $blue;
      text-align: left;
      padding-left: 35px;
      font-size: 15px;
      letter-spacing: 1px;
      font-weight: 300;
      cursor: pointer;
      &:hover {
        background-color: $backupBlue;
      }
      .arrowsec {
        float: right;
        img {
          position: relative;
          top: 4px;
          right: 13px;
          width: 19px;
        }
      }
    }
  }
  h1.big2 {
    padding-top: 100px;
  }
}
#signup {
  margin-top: 100px;
  min-height: 120px;
  text-align: center;
  h1.big1 {
    font-family: $moonLight;
    letter-spacing: 3px;
    word-spacing: -5px;
  }
  p.notbig {
    margin-top: 10px;
    font-family: $noah;
    letter-spacing: 1px;
  }
  .viewbutton.butedit_2 {
    width: 100px;
    float: right;
    margin-top: 75px;
  }
  .inp-holder {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  input.lefz {
    float: left;
    background-color: $igray;
    padding: 15px 20px 15px 28px;
    width: 450px;
    margin-top: 70px;
    font-size: 16px;
    letter-spacing: 2px;
    border: 2px solid $igray2;
    &:focus {
      border: 2px solid red !important;
    }
  }
}
.starcap {
  overflow: hidden;
}
div[id*="starlight-star"] {
  @extend %no-select;
  z-index: -100;
}
.starlight.lipped {
  width: 1000px;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
}
#ablue {
  background-color: $blue;
  width: 1000px;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  margin-top: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  #left_1 {
    height: 100%;
    color: white;
    img {
      transform: rotate(-20deg);
      margin-top: -110px;
      width: 344px;
      margin-left: 75px;
    }
    .a1 {
      margin-left: 80px;
      font-family: $moonLight;
      font-size: 29px;
      width: 382px;
      line-height: 35px;
      letter-spacing: 4px;
      word-spacing: -7px;
      margin-top: 33px;
      text-transform: uppercase;
      text-align: left;
    }
    .a2 {
      padding-top: 15px;
      padding-bottom: 7px;
      font-family: $noah;
      margin-left: 80px;
      line-height: 23px;
      &.nopadding {
        padding-bottom: 0px;
      }
    }
    .a3 {
      margin-top: 33px;
      margin-left: 80px;
      font-family: $noah;
      color: $explainBlue;
      font-size: 13px;
      line-height: 18px;
    }
  }
  #right_1 {
    height: 100%;
    #crad {
      margin-top: 180px;
      width: 100%;
      height: 300px;
      .centa {
        color: white;
        height: 40px;
        border-bottom: 1px solid white;
        margin-left: 50px;
        margin-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        line-height: 20px;
        width: 295px;
        h1.num {
          letter-spacing: 20px;
          font-family: $noah;
          float: left;
          height: 100%;
          width: 90px;
          line-height: 47px;
          letter-spacing: 1px;
          text-align: left;
        }
        .dex {
          float: left;
          font-family: $noah;
          line-height: 47px;
          margin-left: 20px;
          font-size: 20px;
          &.ru {
            margin-left: 0px;
          }
          &.kr {
            margin-left: 10px;
          }
        }
        &.dota {
          border-bottom: 1px dotted white;
        }
        &.last {
          border-bottom: none;
        }
      }
    }
  }
}
.af-label-text {
  font-family: $noah;
  color: rgba(255, 255, 255, 0.819);
  word-spacing: 5px;
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  padding-bottom: 15px;
  letter-spacing: 1px;
  &.subump {
    margin-top: 29px;
    margin-bottom: 10px;
  }
}
.af-1 {
  height: 100px;
  float: left;
  width: 45%;
}
.af-2 {
  height: 100px;
  float: right;
  width: 45%;
}
.af-3 {
  height: 100px;
  width: 100%;
}
.reg-but- {
  color: white;
  height: 50px;
  border-radius: 30px;
  width: 100%;
  line-height: 50px;
  font-family: $moon;
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
.errortext {
  color: #ff7f7f;
  font-family: $moon;
  word-spacing: -2px;
  position: relative;
  top: -10px;
  left: -45px;
}
.succtext {
  color: #00c310;
  font-family: $moon;
  word-spacing: -2px;
  position: relative;
  top: -10px;
  letter-spacing: 1px;
}
.reg-but-1,
.reg-but-3,
.reg-but-4 {
  background-color: #4592f8;
  margin-top: 110px;
  &.green {
    background-color: rgb(71, 163, 75);
    &:hover {
      opacity: 1;
      cursor: initial;
    }
  }
}
.reg-but-2 {
  margin-top: 25px;
  background-color: #734c88;
  &.noclicky {
    opacity: 0.4;
    &:hover {
      cursor: initial;
    }
  }
}
.reg-but-3 {
  margin-top: -50px;
  &.noclicky {
    opacity: 0.4;
    &:hover {
      cursor: initial;
    }
  }
}
.reg-but-4 {
  background-color: #734c88;
  margin-top: 35px;
  &.noclicky {
    opacity: 0.4;
    &:hover {
      cursor: initial;
    }
  }
}
#registrybox {
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}
.etherianCap {
  margin-top: 40px;
}
.countHolder {
  position: absolute;
  z-index: 999;
  right: -8px;
  top: -8px;
  color: rgb(214, 214, 214);
  font-family: $quicksand;
  font-size: 11px;
  background-color: #242130;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  line-height: 23px;
  text-align: center;
  &.analtz {
    right: initial;
    top: 0;
    margin-left: 50px;
  }
}
.dumpetherianImg {
  position: relative;
  display: inline-block;
  margin: 4px;
  img {
    border: 3px solid rgba(255, 255, 255, 0.35);
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  img {
  }
}

#select-drop,
#select-drop2,
#select-drop3 {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("https://woe2.s3.amazonaws.com/Polygon3.png"); /* For Chrome and Safari */
  background-image: url("https://woe2.s3.amazonaws.com/Polygon3.png"); /* For old Firefox (3.6 to 15) */
  background-image: url("https://woe2.s3.amazonaws.com/Polygon3.png"); /* For pre-releases of Internet Explorer  10*/
  background-image: url("https://woe2.s3.amazonaws.com/Polygon3.png"); /* For old Opera (11.1 to 12.0) */
  background-image: url("https://woe2.s3.amazonaws.com/Polygon3.png"); /* Standard syntax; must be last */
  background-position: 113px 15px;
  background-repeat: no-repeat;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px !important;
  line-height: 36px;
  font-size: 18px;
  height: 44px;
  cursor: pointer;
  z-index: 999;
  color: rgba(255, 255, 255, 0.819);
  background-color: #283b60;
  border: 2px solid rgba(255, 255, 255, 0.35);
  position: relative;
  height: 40px;
  padding-left: 15px;
  letter-spacing: 0.5px;
  font-family: $noah;
}
#select-drop2 {
  background-image: none;
  width: calc(100% - 15px) !important;
  z-index: 999;
}
#select-drop3 {
  border: none;
  border-radius: 10px;
  width: 335px !important;
  background-position: 301px 15px;
}
.cubbycontain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  justify-content: space-between;
  .textedme {
    margin-top: 8px;
    font-family: $noah;
    color: rgba(255, 255, 255, 0.819);
    word-spacing: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    padding-bottom: 15px;
    letter-spacing: 1px;
    text-align: center;
  }
  .cubby {
    border-radius: 5px;
    height: 100px;
    border: 3px solid transparent;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &.cancelhov {
      cursor: initial !important;
      opacity: 0.3;
    }
    img.giffy {
      height: 100%;
      width: 100%;
    }
    &.activz {
      border-radius: 15px;
      border: 3px solid white;
      opacity: 1;
    }
    img.cubbyimg {
      position: absolute;
      top: 3px;
      left: 4px;
      height: 90px;
      &.altcubby {
        top: -3px;
      }
    }
  }
}
#undervie {
  background-color: #f7f7f7;
  height: 280px;
  #anightcap {
    width: 1100px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    .arright {
      width: 340px;
      height: 100%;
      float: right;
      text-align: center;
      .tokey {
        margin-top: 44px;
        img {
          height: 70px;
        }
        p {
          letter-spacing: 3px;
          font-family: $quicksand;
          font-size: 13px;
          font-weight: normal;
          line-height: 17px;
          margin-top: 10px;
        }
        .subtokey {
          margin-top: 10px;
          width: 220px;
          margin-left: auto;
          margin-right: auto;
          letter-spacing: 1px;
          font-family: $quicksand;
          font-size: 13px;
          font-weight: normal;
          line-height: 17px;
        }
      }
    }
    .arleft {
      color: black;
      float: left;
      width: 750px;
      height: 64%;
      margin-top: 62px;
      border-right: 1px solid #c6c6c6;
      .lefttopz {
        h1 {
          color: black;
          font-family: $moonLight;
          letter-spacing: 3px;
          font-size: 34px;
          margin-top: 25px;
        }
      }
      .leftmidz {
        margin-top: 20px;
        letter-spacing: 3px;
        font-family: $noah;
        word-spacing: 5px;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 1px;
      }
    }
  }
}
#registrybox {
  width: 320px;
  padding: 25px 40px;
  border: 3px solid rgba(255, 255, 255, 0.35);
  margin-left: auto;
  margin-right: auto;
  border-radius: 23px;
  height: 247px;
  &.editedRoad {
    height: 200px;
  }
}
#noticeBox {
  margin-top: 30px;
  border: 3px solid #162d4c;
  background-color: #162d4c;
  width: 400px;
  border-radius: 15px;
  height: 58px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  &.anedi {
    margin-bottom: 20px;
  }
  img {
    position: absolute;
    left: 12px;
    top: 15px;
    width: 25px;
  }
  .someszh {
    font-family: $noah;
    color: white;
    margin-left: 57px;
    margin-right: 26px;
    text-align: left;
    margin-top: 8px;
  }
}
#roady,
#roady2,
#roady3,
#roady4 {
  padding-bottom: 400px;
  position: relative;
  #circa {
    background-color: rgba(255, 255, 255, 0.1);
    height: 43px;
    width: 43px;
    border-radius: 43px;
    text-align: center;
    line-height: 70px;
    position: absolute;
    left: 44px;
    top: 228px;
    #circa2 {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.3);
      height: 26px;
      width: 26px;
      border-radius: 26px;
      margin-left: auto;
      line-height: 54px;
      text-align: center;
      margin-right: auto;
      position: relative;
      #circa3 {
        display: inline-block;
        background-color: rgba(255, 255, 255, 1);
        height: 10px;
        width: 10px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: -14.5px;
      }
    }
  }
  h1.maintext,
  h1.submaintext {
    text-transform: uppercase;
    font-family: $moonLight;
    letter-spacing: 6px;
    word-spacing: -11px;
    color: white;
    font-size: 38px;
    line-height: 45px;
    margin: 0 auto;
    text-align: center;
  }
  h1.submaintext {
    line-height: 30px;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 3px;
    word-spacing: -5px;
    margin-top: 30px;
    padding-bottom: 100px;
  }
  .belly {
    background-color: $blue;
    min-height: 106px;
    width: 645px;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    text-align: left;
    padding-bottom: 37px;
    position: relative;
    z-index: 999;
    .timez {
      display: block;
      color: $bellytext;
      font-family: $noah;
      padding-top: 24px;
      margin-left: 45px;
      font-size: 14px;
    }
    .thetitle {
      text-transform: uppercase;
      font-family: $moonLight;
      display: block;
      color: white;
      margin-left: 45px;
      letter-spacing: 4px;
      word-spacing: -9px;
      font-size: 24px;
      padding-top: 16px;
    }
    .thebody {
      display: block;
      color: $bellytext;
      font-family: $noah;
      margin-left: 45px;
      margin-right: 45px;
      margin-top: 10px;
    }
  }
}
#roady3 {
  width: 850px;
}
#roady4 {
  width: 1000px;
}
#roady2 {
  padding-bottom: 0;
  h1.submaintext {
    padding-bottom: 0;
  }
}
img.aTwitterpic {
  height: 50px;
  margin-top: 70px;
}
.submaintext.alternate1 {
  line-height: 24px !important;
}
#teambox {
  min-height: 620px;
  #foundergrid,
  #foundergrid2 {
    height: 330px;
    width: 1100px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 10fr 10fr 10fr 10fr;
    grid-row-gap: 4em;
    .founder_spot {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      .founder_name {
        color: #232338;
        font-family: $quicksand;
        font-size: 22px;
        font-weight: bold;
        margin-top: 30px;
      }
      .founder_role {
        color: #67788a;
        font-weight: medium;
        font-family: $quicksand;
        font-size: 17px;
        font-weight: bold;
        margin-top: 10px;
      }
      img {
        height: 200px;
      }
    }
  }
  #foundergrid2 {
    margin-bottom: 70px;
    width: 900px;
    grid-template-columns: 1fr 10fr 10fr 10fr 1fr;
  }
}
#mainTimeline {
  position: relative;
  //
  width: 700px;
  min-height: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -210px;
  img#mainbar_1 {
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    height: 900px;
    margin-top: 20px;
  }
  img#sidebar_1 {
    position: absolute;
    height: 250px;
    margin-left: -370px;
    margin-top: 79px;
  }
  img#sidebar_2 {
    position: absolute;
    height: 180px;
    margin-left: 72px;
    margin-top: 319px;
  }
  img#sidebar_3 {
    position: absolute;
    height: 150px;
    margin-left: 91px;
    margin-top: 329px;
  }
  img#sidebar_4 {
    position: absolute;
    height: 150px;
    margin-left: -286px;
    margin-top: 519px;
  }
  img#sidebar_5 {
    position: absolute;
    height: 150px;
    margin-left: 71px;
    margin-top: 689px;
  }
}
.light-top-new {
  .tita3 {
    padding-top: 136px;
    color: $dBlack;
    font-family: $moonBold;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    word-spacing: -1.5px;
    text-align: center;
  }
  .conta3 {
    min-height: 300px;
    width: 720px;
    margin: 35px auto;
    color: #232338;
    font-family: $quicksand;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: 2px;
    word-spacing: 1px;
    &.t2z {
      text-align: center;
      min-height: 134px;
    }
  }
}
#oceanlike {
  img#oceanseen {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 256px);
  }
  position: relative;
  #cardinalWrap {
    height: 100%;
    width: 1100px;
    margin: 0 auto;
  }
  overflow: hidden;
  height: 700px;
  background: rgb(37, 203, 227);
  background: linear-gradient(180deg, rgba(37, 203, 227, 1) 0%, rgba(5, 103, 165, 1) 100%);
  #cardinalLeft {
    padding-left: 10px;
    float: left;
    .romanbutton {
      float: left;
      width: 210px;
      background-color: #162d4c;
      font-size: 22px;
      height: 51px;
      line-height: 51px;
      border-radius: 28px;
      margin-top: 50px;
      &:hover {
        background-color: white;
      }
    }
  }
  #cardinalRight {
    position: relative;
    height: 120px;
    width: 120px;
    float: right;
    margin-top: 94px;
    width: 50%;
    margin-right: -74px;
    font-family: $quicksand;
    font-size: 19px;
    text-align: center;
    #circ_1 {
      position: absolute;
      background-color: #dff6f9;
      height: 280px;
      width: 280px;
      border-radius: 280px;
      top: -29px;
      left: 40px;
      color: #232338;
      text-align: center;
      #circ_1_in {
        // background-color: orange;
        margin-top: 90px;
        width: 68%;
        margin-left: auto;
        margin-right: auto;
        line-height: 26px;
      }
    }
    #circ_2 {
      position: absolute;
      background-color: #bee8ff;
      height: 210px;
      width: 210px;
      border-radius: 210px;
      top: 133px;
      left: 299px;
      #circ_2_in {
        font-size: 21px;
        margin-top: 93px;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    #circ_3 {
      position: absolute;
      background-color: #0f4868;
      height: 180px;
      width: 180px;
      border-radius: 180px;
      top: 260px;
      left: 121px;
      #circ_3_in {
        font-size: 16px;
        margin-top: 57px;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        color: white;
      }
    }
    #circ_4 {
      position: absolute;
      background-color: #dff6f9;
      height: 125px;
      width: 125px;
      border-radius: 125px;
      top: 348px;
      left: 303px;
      #circ_4_in {
        font-size: 17px;
        margin-top: 52px;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  #cardinalTop {
    width: 310px;
    color: white;
    font-family: $moonBold;
    font-size: 35px;
    letter-spacing: 6px;
    word-spacing: -17px;
    padding-top: 80px;
    line-height: 44px;
  }
  .cardinalMid {
    font-family: $quicksand;
    color: white;
    width: 400px;
    padding-top: 48px;
    line-height: 32px;
    font-size: 20px;
  }
}
#lightgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 4em;
  grid-column-gap: 1.5em;
  height: 930px;
  max-width: 1400px;
  margin: 50px auto;
  .roundClip {
    border-radius: 0 29px 0 0;
    overflow: hidden;
    height: 300px;
    width: 190px;
    position: relative;
    float: right;
  }
  .shot {
    height: 403px;
    position: relative;
    .somecontain {
      position: absolute;
      margin-left: 15px;
      h1 {
        margin-top: 66px;
        font-family: $moonLight;
        text-align: left;
        font-size: 30px;
        float: left;
      }
      p {
        float: left;
        text-align: left;
        width: 300px;
        margin-top: 20px;
        font-family: $noah;
        line-height: 32px;
        font-size: 17px;
      }
    }
    &.filled {
      background-color: $aNewDk;
      border-radius: 29px;
      position: relative;
      .subb {
        color: white;
        font-family: $noah;
        position: absolute;
        top: 274px;
        left: 29px;
        width: 180px;
        text-align: left;
        letter-spacing: 1px;
        word-spacing: -1px;
        font-size: 16px;
        line-height: 23px;
        &.ru {
          font-size: 18px;
          line-height: 19px;
        }
        &.kr {
          font-size: 23px;
          line-height: 26px;
        }
        &.ch {
          font-size: 23px;
          line-height: 26px;
        }
        &.jp {
          font-size: 23px;
          line-height: 26px;
        }
      }
      .titlesec {
        font-family: $moonLight;
        font-size: 36px;
        letter-spacing: 4px;
        margin-top: 175px;
        position: absolute;
        left: 29px;
        top: 49px;
        color: $sun;
        &.ocean {
          color: $ocean;
        }
        &.life {
          color: $life;
        }
        &.deathtt {
          color: $death;
        }
        &.astral {
          color: $astral;
        }
      }
      img.tarrot {
        float: left;
        margin-top: 30px;
        margin-left: 30px;
        height: 150px;
        position: absolute;
      }
      .imgholder {
        position: absolute;
        height: 260px;
        width: 180px;
        border-radius: 0 0 300px 300px;
        overflow: hidden;
        right: -63px;
        top: -48px;
        &.suntt {
          background: rgb(227, 66, 81);
          background: linear-gradient(180deg, rgba(227, 66, 81, 1) 0%, rgba(239, 102, 68, 1) 100%);
        }
        &.ocean {
          background: rgb(37, 203, 227);
          background: linear-gradient(180deg, rgba(37, 203, 227, 1) 0%, rgba(5, 103, 165, 1) 100%);
        }
        &.life {
          background: rgb(246, 241, 124);
          background: linear-gradient(180deg, rgba(246, 241, 124, 1) 0%, rgba(154, 247, 179, 1) 100%);
        }
        &.deathtt {
          background-color: #5a585a;
        }
        &.astral {
          background: rgb(85, 43, 124);
          background: linear-gradient(180deg, rgba(85, 43, 124, 1) 0%, rgba(137, 41, 124, 1) 100%);
        }
        img {
          width: 77px;
          top: 87px;
          left: 27px;
          position: absolute;
        }
      }
    }
  }
}
#roadmap {
  background-image: url("https://woe2.s3.amazonaws.com/gradient-starless.jpg");
  background-size: cover;
  background-color: $backupBlue;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  text-align: center;
}
#lightgrad {
  padding-bottom: 130px;
  background-position: center top;
  background-image: url("https://woe2.s3.amazonaws.com/LightGradient.jpg");
  // background-image: url("https://woe2.s3.amazonaws.com/LightGradient(flipped).jpg");
  background-size: cover;
  min-height: 1500px;
  width: 100%;
  text-align: center;
  #signGrid {
    font-family: $noah;
    margin-top: -170px;
    font-size: 70px;
    grid-template-columns: 100fr 1fr 100fr 1fr 100fr;
    display: grid;
    justify-items: center;
    color: $backupBlue;
  }
  #classGrid {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
  .oval {
    height: 290px;
    width: 210px;
    border-radius: 130px;
    overflow: hidden;
    position: relative;
    img.back {
      height: 100%;
      overflow: hidden;
      margin-left: -16px;
    }
    img.main {
      position: absolute;
      top: 45px;
      left: 14px;
      height: 170px;
      &img.left_1 {
        top: 52px !important;
        left: 18px !important;
      }
      &img.left_2 {
        height: 190px !important;
        top: 30px !important;
        left: 8px !important;
      }
    }
  }
  .moreBigText {
    text-transform: uppercase;
    font-family: $moonLight;
    font-size: 30px;
    letter-spacing: 4px;
    word-spacing: -5px;
    width: 560px;
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
  }
  .moreMedText {
    font-family: $noah;
    width: 560px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 22px;
    word-spacing: -1px;
  }
  .lightclip {
    background-color: rgba(256, 256, 256, 0.43);
    width: 1200px;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-top: 130px;
    padding-bottom: 20px;
    padding-top: 20px;
    .alefter {
      width: 50%;
      float: left;
      margin-top: 200px;
      margin-bottom: 200px;
      h1 {
        text-align: left;
        margin-left: 100px;
        text-transform: uppercase;
        max-width: 420px;
        letter-spacing: 5px;
        font-size: 26px;
        word-spacing: -1px;
      }
      p {
        margin-top: 30px;
        margin-left: 100px;
        max-width: 300px;
        text-align: left;
        line-height: 24px;
        font-family: $noah;
      }
    }
    .arighter {
      width: 50%;
      float: right;
      img {
        width: 85%;
        margin-top: 120px;
      }
    }
  }
}
.not-thin-wrap {
  width: 1200px;
  margin: 0 auto;
}
#leftEgg {
  float: left;
  width: 50%;
  .someimgcon {
    width: 600px;
    height: 570px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background-color: #5a585b;
    img.adjj {
      height: 570px;
      width: 120%;
      bottom: 0px;
      position: absolute;
      left: -75px;
      &.adjust_sun {
        left: -52px;
      }
      &.adjust_life {
        left: -85px;
      }
      &.adjust_death {
        left: -52px;
        width: 100%;
        left: 0;
      }
      &.adjust_ocean {
        left: -52px;
        width: 100%;
        left: 0;
        bottom: initial;
        top: 0;
      }
    }
    @keyframes slidein {
      from {
        top: -20px;
      }

      to {
        top: 35px;
      }
    }
    img.eggz {
      position: absolute;
      z-index: 999;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      top: 0;
      height: 400px;
      animation: slidein 3s infinite alternate;
    }
  }
}
#rightEgg {
  // margin-top: 30px;
  width: 40%;
  margin-left: 8%;
  float: right;
  color: white;
  text-transform: uppercase;
  #noticeBox {
    margin-left: initial;
    margin-right: initial;
    width: 380px;
    .someszh.someszh2 {
      // font-size:16px;
      text-transform: none;
    }
  }
  .topLane {
    font-family: $noah;
    position: relative;
    .creeps {
      display: inline-block;
      position: absolute;
      top: 16px;
      font-size: 13px;
    }
    .timur {
      display: inline-block;
      margin-left: 20px;
      font-size: 34px;
      margin-left: 65px;
    }
  }
  h1.justBuy {
    margin-top: 10px;
    font-family: $moonLight;
    letter-spacing: 4px;
    font-size: 39px;
    color: white;
  }
  p.mainbuytext {
    text-transform: initial;
    font-family: $noah;
    line-height: 28px;
    width: 390px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .reg-but- {
    width: 335px;
    text-align: center;
    &.new-edito {
      margin-top: 13px !important;
      margin-left: auto;
      margin-right: auto;
      width: 50% !important;
    }
  }

  .pillowbox {
    height: 352px;
    border: 2px solid $pilloutline;
    width: 360px;
    border-radius: 8px;
    padding-top: 20px;
    padding-left: 25px;

    .atoptext {
      font-family: $noah;
      margin-bottom: 10px;
      font-size: 14px;
    }
    .midlane {
      position: relative;
      .priceETH {
        display: inline-block;
        font-family: $noah;
        font-size: 32px;
        color: white;
      }
      .priceUSD {
        position: absolute;
        display: inline-block;
        font-family: $noah;
        margin-left: 30px;
        font-size: 25px;
        top: 3px;
      }
    }
    .botlane {
      position: relative;
      .af-label-text {
        margin-top: 20px;
      }
      .counter {
        background-color: white;
        border-radius: 18px;
        height: 33px;
        width: 120px;
        margin-top: 20px;
        position: relative;
        display: inline-block;
        color: black;
        font-family: $noah;
        line-height: 33px;
        text-align: center;
        font-size: 22px;
        .click-left {
          float: left;
          width: calc(20% - 12px);
          font-size: 33px;
          margin-left: 12px;
          margin-top: -3px;
        }
        .countDump {
          float: left;
          width: 60%;
          position: relative;
        }
        .click-right {
          float: left;
          width: calc(20%);
          font-size: 33px;
          margin-left: -6px;
        }
      }
      .buynow {
        display: inline-block;
        position: absolute;
        margin-top: 20px;
        left: 145px;
        background-color: $buttonblue;
        line-height: 32px;
        text-align: center;
        border-radius: 18px;
        height: 33px;
        width: 185px;
        font-family: $moonBold;
        letter-spacing: 1px;
        font-size: 14px;
      }
    }
  }
  .underBuy {
    a {
      .linksa1 {
        text-transform: initial;
        font-family: $noah;
        color: white;
        display: inline-block;
        margin-right: 30px;
        font-size: 12px;
        margin-top: 50px;
      }
    }
  }
}
#sigmagrind {
  display: block;
  border: none;
  color: white;
  width: 7px;
  background: white;
  // height: 2277px;
  // background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 1100, from(#fff), to(rgba(0, 0, 0, 0)));
  height: 2000px;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 970, from(#fff), to(rgba(0, 0, 0, 0)));
  margin-left: 162px;
  position: absolute;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar {
  display: none;
}
.slide-controler {
  z-index: 999;
  width: 70px;
  margin-top: 10px;
  height: calc(75vh - 150px);
  overflow: scroll;
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
  margin-bottom: 30px;
  @extend %no-select;
  @extend %no-drag;
  right: 33px;
}
.arrow-container {
  @extend %no-select;
  @extend %no-drag;
  width: 70px;
  margin-top: 10px;
  height: calc(75vh - 150px);
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 20px;
  top: 50%;
  margin-bottom: 30px;
  img.arrowme {
    position: absolute;
    bottom: -43px;
    left: 8px;
  }
}
.slidnav {
  z-index: 999;
  cursor: pointer !important;
  position: absolute;
  margin-top: 10px;
  list-style-type: none;
  color: white;
  li {
    z-index: 999;
    font-family: $noah;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.32);
    width: 65px;
    text-align: center;
    padding: 10px 0;
    font-size: 17px;
    &.active {
      color: rgba(255, 255, 255, 1);
      font-size: 21px;
      padding: 9px 0;
      &::after {
        content: " <";
      }
    }
  }
}
.mon-scroll {
  width: 70px;
  margin-top: 10px;
  height: calc(75vh - 150px);
  overflow: hidden;
  background-color: orange;
}
#woe-image-capture {
  cursor: pointer;
  &:hover {
    background-color: $blue;
    // img.main {
    //     // opacity: 0;
    // }
    img.gold {
      opacity: 1;
    }
  }
  img.main {
    @extend %no-select;
    @extend %no-drag;
    position: absolute;
    bottom: 5%;
    right: 15%;
    width: 30%;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.23s 0.15s, transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
    -webkit-filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.899));
    filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.906));
  }
  img.gold {
    @extend %no-select;
    @extend %no-drag;
    position: absolute;
    opacity: 0;
    bottom: 5%;
    right: 15%;
    width: 30%;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.23s 0.15s, transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
    // -webkit-filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.899));
    // filter: drop-shadow(5px 5px 5px rgba(51, 51, 51, 0.906));
  }
}
.woedexwrap {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  #watermark {
    color: rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
    // font-family: $moonBold;
    // font-family: $moonLight;
    font-family: $noah;
    letter-spacing: 10px;
    font-size: 170px;
    word-spacing: -3px;
    position: fixed;
    bottom: -50px;
    left: 86px;
    @extend %no-select;
    @extend %no-drag;
  }
  .dex-wrap {
    display: block;
    padding: 15px;
    width: 1170px;
    display: flex;
    .dex-cont {
      width: 50%;
      height: 100%;
      color: white;
      h1.woedex-type {
        text-transform: uppercase;
        font-family: $moonLight;
        letter-spacing: 8px;
        font-size: 14px;
        word-spacing: -3px;
        padding-bottom: 40px;
        height: auto;
        @extend %no-select;
        @extend %no-drag;
      }
      h1.woedex-name {
        text-transform: uppercase;
        font-family: $moonLight;
        letter-spacing: 10px;
        font-size: 107px;
        word-spacing: -3px;
        padding-bottom: 60px;
        @extend %no-select;
        @extend %no-drag;
      }
      h1.woedex-disc {
        text-transform: uppercase;
        font-family: $moonLight;
        letter-spacing: 4px;
        font-size: 22px;
        word-spacing: -3px;
        padding-bottom: 26px;
        @extend %no-select;
        @extend %no-drag;
      }
      .woedex-info {
        font-family: $noah;
        line-height: 27px;
        @extend %no-select;
        @extend %no-drag;
      }
    }
  }
}
.sun-bg {
  background: linear-gradient(340deg, #e74d5c, #f3774d, #fabea3);
}
.ocean-bg {
  background: linear-gradient(340deg, #004996, #21d1e6, #6de7ee);
}
.life-bg {
  background: linear-gradient(340deg, rgb(71, 163, 75), #ceca5a);
}
.death-bg {
  background: linear-gradient(340deg, rgb(75, 81, 87), rgba(181, 194, 207, 1));
}
.stock-bg {
  background: linear-gradient(340deg, #1f1f2c, rgba(181, 194, 207, 1));
}
.astral-bg {
  background: linear-gradient(340deg, #805eb5, #cb8cb9);
}
.w-button {
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;
  height: 40px;
  min-width: 70px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: #343434;
  color: white;
  line-height: 40px;
  text-align: center;
  font-size: 19px;
  margin-right: 10px;
  &:hover {
    background-color: #131313;
  }
  &.active {
    background-color: #009c1f;
  }
}
#footer {
  height: 170px;
  background-image: url("https://woe2.s3.amazonaws.com/DarkGradient.png");
  background-size: cover;
  background-color: $backupBlue;
  &.imageless {
    position: absolute;
    background-image: none;
    background-color: initial;
    bottom: 0;
    width: 100%;
    left: 0;
    img {
      float: left;
    }
  }
  .footleft {
    width: 50%;
    float: left;
    height: 100%;
    img {
      height: 60px;
      margin-left: 50px;
      margin-top: 58px;
    }
  }
  .footright {
    height: 100%;
    float: right;
    font-family: $noah;
    color: white;
    margin-right: 150px;
    line-height: 170px;
    font-size: 13px;
  }
}
.cell-t {
  position: relative;
  &.cell-t-1 {
    grid-row-start: 1fr;
  }
  text-align: center;
}
.linedUp {
  font-family: $quicksand;
  color: white;
  text-align: left;
  line-height: 30px;
}
body {
  // background-color: rgb(27, 27, 27);
}
#inventory {
  #inv-wrap {
    width: 1200px;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .inv-dropdowns {
    width: 510px;
    .inv-drop-1,
    .inv-drop-2 {
      height: 60px;
      width: 44%;
      margin-right: 6%;
      background-color: rgba(255, 255, 255, 0.4);
      float: left;
    }
  }
  .inv-grid-contain {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 500px;
    margin-top: 77px;
    .inv-cell {
      background-color: rgba(74, 59, 57, 0.4);
      height: 76px;
      width: 76px;
      margin-bottom: 10px;
      border-radius: 7px;
      cursor: pointer;
      overflow: hidden;
      border: 2px solid transparent;
      &.active-ocean {
        border: 2px solid #91e4ea;
      }
      &.active-sun {
        border: 2px solid #fba13c;
      }
      &.active-life {
        border: 2px solid #329854;
      }
      &.active-death {
        border: 2px solid #c8c8f4;
      }
      &.active-astral {
        border: 2px solid #fecdff;
      }

      &:hover {
        border: 2px solid white;
      }
      img {
        width: 145px;
        margin-left: -20px;
        border-radius: 7px;
        &.adj-0 {
        }
        &.adj-1 {
          width: 145px;
          margin-left: -30px;
          margin-top: -33px;
        }
        &.adj-2 {
          width: 145px;
          margin-left: -50px;
          margin-top: -10px;
        }
        &.adj-3 {
          margin-left: -50px;
          margin-top: -20px;
        }
        &.adj-4 {
          width: 175px;
          margin-left: -43px;
          margin-top: -20px;
        }
        &.adj-5 {
          width: 145px;
          margin-left: -40px;
          border-radius: 7px;
        }
        &.adj-6 {
          margin-left: -30px;
          border-radius: 7px;
        }
        &.adj-7 {
          margin-left: -54px;
          border-radius: 7px;
        }
        &.adj-8 {
          margin-left: -30px;
          border-radius: 7px;
        }
        &.adj-9 {
          margin-left: -30px;
          border-radius: 7px;
        }
        &.adj-10 {
          margin-left: -30px;
          border-radius: 7px;
        }

        &.adj-11 {
          width: 145px;
          margin-left: -23px;
          margin-top: -5px;
        }
        &.adj-12 {
          width: 145px;
          margin-left: -43px;
          margin-top: -5px;
        }
        &.adj-13 {
          width: 145px;
          margin-left: -13px;
          margin-top: -28px;
        }
        &.adj-14 {
          width: 145px;
          margin-left: -28px;
          margin-top: -15px;
        }
        &.adj-15 {
          width: 145px;
          margin-left: -23px;
          margin-top: -5px;
        }
        /*

            width: 145px;
            margin-left:-10px;
            margin-top:-10px; 

*/
        &.adj-16 {
          margin-left: -30px;
          margin-top: -10px;
        }
        &.adj-17 {
          margin-left: -10px;
          margin-top: -10px;
        }
        &.adj-18 {
          margin-left: -70px;
          margin-top: -10px;
        }
        &.adj-19 {
          margin-left: -30px;
          margin-top: 0px;
        }
        &.adj-20 {
          margin-left: -50px;
          margin-top: 0px;
        }
        &.adj-21 {
          margin-top: -12px;
          margin-left: -12px;
        }
        &.adj-22 {
          margin-top: -12px;
          margin-left: -12px;
        }
        &.adj-23 {
          margin-top: -12px;
          margin-left: -12px;
        }
        &.adj-24 {
          margin-top: -2px;
          margin-left: -42px;
        }
        &.adj-25 {
          margin-left: -46px;
        }

        &.adj-26 {
          // margin-left: -30px;
          // margin-top: -33px;
          margin-left: -25px;
        }
        &.adj-27 {
          margin-left: -38px;
        }
        &.adj-28 {
          margin-left: -30px;
        }
        &.adj-29 {
          margin-left: -25px;
        }
        &.adj-30 {
          margin-left: -25px;
        }
        &.adj-31 {
          margin-left: -50px;
          margin-top: -20px;
        }
        &.adj-32 {
          width: 165px;
          margin-left: 0px;
          margin-top: -21px;
        }
        &.adj-33 {
          margin-left: -40px;
        }
        &.adj-34 {
          margin-top: -21px;
          margin-left: -21px;
        }
        &.adj-35 {
          margin-left: -32px;
        }
        &.adj-36 {
          width: 165px;
          margin-left: -50px;
          margin-top: -31px;
        }
        &.adj-37 {
          margin-left: -30px;
        }
        &.adj-38 {
          width: 185px;
          margin-top: -80px;
          margin-left: -4px;
        }
        &.adj-39 {
          margin-left: -50px;
        }
        &.adj-40 {
          margin-top: -100px;
          margin-left: -172px;
          width: 270px;
        }
        &.adj-41 {
          margin-left: -50px;
          margin-top: -18px;
        }
        &.adj-42 {
          margin-left: -50px;
          margin-top: -18px;
        }
        &.adj-43 {
          margin-left: -50px;
          margin-top: -8px;
        }
        &.adj-44 {
          margin-left: -10px;
          margin-top: -8px;
        }
        &.adj-45 {
          margin-left: -30px;
          margin-top: -18px;
        }
        &.adj-46 {
          margin-left: -40px;
          margin-top: -20px;
        }
        &.adj-47 {
          margin-left: 0px;
          margin-top: -21px;
        }
        &.adj-48 {
          margin-left: -30px;
        }
        &.adj-49 {
          margin-left: -30px;
        }
        &.adj-50 {
        }
        &.adj-51 {
        }
        &.adj-52 {
          margin-left: -30px;
          margin-top: -30px;
        }
        &.adj-53 {
          margin-left: -30px;
          margin-top: -30px;
        }
        &.adj-54 {
          margin-top: -30px;
          margin-left: -40px;
        }
        &.adj-55 {
          margin-left: -30px;
          margin-top: -30px;
        }

        &.adj-56 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-57 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-58 {
          margin-top: 0px;
          margin-left: -26px;
        }
        &.adj-59 {
          margin-top: -20px;
          margin-left: -33px;
        }
        &.adj-60 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-61 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-62 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-63 {
          margin-top: -20px;
          margin-left: -29px;
        }
        &.adj-64 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-65 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-66 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-67 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-68 {
          margin-top: 0px;
          margin-left: -20px;
        }
        &.adj-69 {
          margin-top: -34px;
          margin-left: -50px;
        }
        &.adj-70 {
          margin-top: -1px;
          margin-left: -35px;
        }
        &.adj-71 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-72 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-73 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-74 {
          margin-top: -20px;
          margin-left: -38px;
        }
        &.adj-75 {
          margin-top: -10px;
          margin-left: -50px;
        }
        &.adj-76 {
          margin-top: -10px;
          margin-left: -50px;
        }
        &.adj-77 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-78 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-79 {
          margin-top: 0px;
          margin-left: -10px;
        }
        &.adj-80 {
          margin-top: -10px;
          margin-left: -20px;
        }
        &.adj-81 {
          margin-top: -40px;
          margin-left: -60px;
        }
        &.adj-82 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-83 {
          margin-top: -40px;
          margin-left: -10px;
        }
        &.adj-84 {
          margin-top: -20px;
          margin-left: -35px;
        }
        &.adj-85 {
          margin-top: -40px;
          margin-left: -40px;
        }
        &.adj-86 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-87 {
          margin-top: -20px;
          margin-left: -10px;
        }
        &.adj-88 {
          margin-top: -30px;
          margin-left: -15px;
        }
        &.adj-89 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-90 {
          margin-top: -20px;
          margin-left: -36px;
        }
        &.adj-91 {
          margin-top: -10px;
          margin-left: -50px;
        }
        &.adj-92 {
          margin-top: -33px;
          margin-left: -30px;
        }
        &.adj-93 {
          margin-top: -20px;
          margin-left: -34px;
        }
        &.adj-94 {
          margin-top: -10px;
          margin-left: -30px;
        }
        &.adj-95 {
          margin-top: -29px;
          margin-left: -56px;
        }
        &.adj-96 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-97 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-98 {
          margin-top: -40px;
          margin-left: 0px;
        }
        &.adj-99 {
          margin-top: -20px;
          margin-left: -28px;
        }
        &.adj-100 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-101 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-102 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-103 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-104 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-105 {
          margin-top: -40px;
          margin-left: -10px;
        }
        &.adj-106 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-107 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-108 {
          margin-top: -20px;
          margin-left: -38px;
        }
        &.adj-109 {
          margin-top: -20px;
          margin-left: -28px;
        }
        &.adj-110 {
          margin-top: -20px;
          margin-left: -39px;
        }
        &.adj-111 {
          margin-top: -20px;
          margin-left: -33px;
        }
        &.adj-112 {
          margin-top: -7px;
          margin-left: -15px;
        }
        &.adj-113 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-114 {
          margin-top: -20px;
          margin-left: -41px;
        }
        &.adj-115 {
          margin-top: -20px;
          margin-left: -29px;
        }
        &.adj-116 {
          margin-top: -28px;
          margin-left: -3px;
        }
        &.adj-117 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-118 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-119 {
          margin-top: -2px;
          margin-left: -38px;
        }
        &.adj-120 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-121 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-122 {
          margin-top: -10px;
          margin-left: -28px;
        }
        &.adj-123 {
          margin-top: -40px;
          margin-left: 0px;
        }
        &.adj-124 {
          margin-top: -20px;
          margin-left: -28px;
        }
        &.adj-125 {
          margin-top: -20px;
          margin-left: -40px;
        }
        &.adj-126 {
          margin-top: -20px;
          margin-left: -40px;
        }
        &.adj-127 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-128 {
          margin-top: -7px;
          margin-left: -20px;
        }
        &.adj-129 {
          margin-top: -55px;
          margin-left: -30px;
        }
        &.adj-130 {
          margin-top: -20px;
          margin-left: -40px;
        }
        &.adj-131 {
          margin-top: 0px;
          margin-left: 0px;
        }
        &.adj-132 {
          margin-top: -45px;
          margin-left: -30px;
        }
        &.adj-133 {
          margin-top: -20px;
          margin-left: -17px;
        }
        &.adj-134 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-135 {
          margin-top: -20px;
          margin-left: -40px;
        }
        &.adj-136 {
          margin-top: -20px;
          margin-left: -15px;
        }
        &.adj-137 {
          margin-top: -20px;
          margin-left: -25px;
        }
        &.adj-138 {
          margin-top: 0px;
          margin-left: -33px;
        }
        &.adj-139 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-140 {
          margin-top: -60px;
          margin-left: -30px;
        }
        &.adj-141 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-142 {
          margin-top: -20px;
          margin-left: -21px;
        }
        &.adj-143 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-144 {
          margin-top: -20px;
          margin-left: -36px;
        }
        &.adj-145 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-146 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-147 {
          margin-top: -20px;
          margin-left: -29px;
        }
        &.adj-148 {
          margin-top: -20px;
          margin-left: -40px;
        }
        &.adj-149 {
          margin-top: -20px;
          margin-left: -20px;
        }
        &.adj-150 {
          margin-top: -30px;
          margin-left: -14px;
        }
        &.adj-151 {
          margin-top: -10px;
          margin-left: -10px;
        }
        &.adj-152 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-153 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-154 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-155 {
          margin-top: -8px;
          margin-left: -50px;
        }
        &.adj-156 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-157 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-158 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-159 {
          margin-top: -20px;
          margin-left: -55px;
        }
        &.adj-160 {
          margin-top: -41px;
          margin-left: -70px;
          width: 260px;
        }
        &.adj-161 {
          margin-top: 5px;
          margin-left: -50px;
        }
        &.adj-162 {
          margin-top: -20px;
          margin-left: -38px;
        }
        &.adj-163 {
          margin-top: -20px;
          margin-left: -28px;
        }
        &.adj-164 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-165 {
          margin-top: -13px;
          margin-left: -38px;
        }
        &.adj-166 {
          margin-top: -40px;
          margin-left: -50px;
        }
        &.adj-167 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-168 {
          margin-top: -20px;
          margin-left: -34px;
        }
        &.adj-169 {
          margin-top: -20px;
          margin-left: -34px;
        }
        &.adj-170 {
          margin-top: -40px;
          margin-left: -59px;
        }
        &.adj-171 {
          margin-top: -20px;
          margin-left: -32px;
        }
        &.adj-172 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-173 {
          margin-top: -20px;
          margin-left: -18px;
        }
        &.adj-174 {
          margin-top: -20px;
          margin-left: -60px;
        }
        &.adj-175 {
          margin-top: -20px;
          margin-left: -22px;
        }
        &.adj-176 {
          margin-top: -42px;
          margin-left: -29px;
        }
        &.adj-177 {
          margin-top: -20px;
          margin-left: -56px;
        }
        &.adj-178 {
          margin-top: -32px;
          margin-left: -50px;
        }
        &.adj-179 {
          margin-top: -20px;
          margin-left: -28px;
        }
        &.adj-180 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-181 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-182 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-183 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-184 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-185 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-186 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-187 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-188 {
          margin-top: -20px;
          margin-left: -50px;
        }
        &.adj-189 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-190 {
          margin-top: -40px;
          margin-left: 0px;
          margin-left: -30px;
        }
        &.adj-191 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-192 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-193 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-194 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-195 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-196 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-197 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-198 {
          margin-top: -10px;
          margin-left: -20px;
        }
        &.adj-199 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-200 {
          margin-top: -20px;
          margin-left: -30px;
        }
        &.adj-201 {
          margin-top: 0px;
          margin-left: -30px;
        }
        &.adj-202 {
          margin-top: 10px;
          margin-left: -71px;
          width: 235px;
        }
        &.adj-203 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-204 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-205 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-206 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-207 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-208 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-209 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-210 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-211 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-212 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-213 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-214 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-215 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-216 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-217 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-218 {
          margin-top: -20px;
          margin-left: 0px;
        }
        &.adj-219 {
          margin-top: -20px;
          margin-left: 0px;
        }
      }
    }
  }
  .inv-left {
    width: 480px;
    height: 100vh;
    margin-top: 74px;
    float: left;
  }
  .craft-button-oval,
  .stake-button-oval {
    background-color: rgba(255, 255, 255, 0.3);
    height: 56px;
    width: 234px;
    border-radius: 40px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    cursor: pointer;
  }
  .craft-button,
  .stake-button {
    position: relative;
    top: 5.5px;
    background-color: rgba(255, 255, 255, 0.7);
    height: 45px;
    line-height: 45px;
    text-align: center;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
    font-family: $moon;
    color: #164b7b;
    letter-spacing: 2px;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .stake-button-oval {
    margin-top: 50px;
    background-color: rgba(22, 45, 76, 0.3);
    width: 214px;
  }
  .stake-button {
    width: 200px;
    color: white;
    background-color: rgba(22, 45, 76, 1);
    &:hover {
      background-color: rgb(3, 21, 44);
    }
  }

  .inv-mid {
    width: 450px;
    height: 100vh;
    float: left;
    .token-bal {
      font-family: $noah;
      background-color: rgba(255, 255, 255, 0.6);
      height: 35px;
      width: 280px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 20px;
      margin-top: 25px;
      line-height: 36px;
      text-align: center;
      img {
        height: 20px;
        position: relative;
        top: 4px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .etherian-text-desc {
      text-align: center;
      color: white;
      font-family: $moon;
      letter-spacing: 1px;
      word-spacing: -3px;
      &.-desc-life {
        color: #329854;
      }
      .etd-line-1 {
        letter-spacing: 2px;
        font-size: 25px;
        text-transform: uppercase;
        margin-top: 14px;
        word-spacing: -5px;
      }
      .etd-line-2 {
        margin-top: 8px;
        img {
          width: 17px;
          position: relative;
          top: 4px;
        }
      }
    }
    .eoval-border {
      top: 6px;
      height: 340px;
      width: 260px;
      border-radius: 180px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      &.--sun {
        border: 2px solid #fba13c;
      }
      &.--ocean {
        border: 2px solid #67ceed;
      }
      &.--life {
        border: 2px solid #329854;
      }
      &.--death {
        border: 2px solid #c8c8f4;
      }
      &.--astral {
        border: 2px solid #fecdff;
      }
    }
    .eoval {
      background-color: rgba(31, 31, 44, 0.4);
      height: 320px;
      width: 240px;
      border-radius: 180px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 10px;
      img {
        width: 100%;
        margin-top: 33px;
      }
    }
  }
  .inv-right {
    width: 270px;
    height: 100vh;
    float: left;
    .right-info-box {
      color: #162d4c;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 12px;
      height: 370px;
      width: 255px;
      margin-top: 130px;
      float: right;
      -webkit-box-shadow: 0px 6px 9px 4px rgba(166, 175, 184, 0.45);
      box-shadow: 0px 6px 9px 4px rgba(166, 175, 184, 0.45);
      .rib-line-1 {
        font-family: $moon;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1.5px;
        word-spacing: -2px;
        margin-top: 85px;
        font-size: 20px;
      }
      .rib-line-2 {
        font-family: $moon;
        text-align: left;
        letter-spacing: 1.5px;
        word-spacing: -2px;
        margin-top: 25px;
        font-size: 14px;
        width: 187px;
        margin-left: auto;
        margin-right: auto;
        line-height: 20px;
      }
    }
  }
}
.start {
  width: 100%;
  height: calc(100vh + 0px);
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(-225deg, #ff3cac 0%, #562b7c 52%, #2b86c5 100%);
  background-size: 600% 600%;
  opacity: 0.65;
  -webkit-animation: AnimationName 9s ease infinite;
  -moz-animation: AnimationName 9s ease infinite;
  animation: AnimationName 9s ease infinite;
}

/* START FOR OLD BACKGROUNDS CSS */
* {
  margin: 0;
  padding: 0;
}
.restrain {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.ritebor {
  border-left: 1px solid rgb(240, 240, 240);
}
.death {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  /* background-image: -webkit-radial-gradient(circle, rgba(64,64,64,1), rgba(0,0,0,1)); */
}
.death:before,
.death:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url('../images/animations/smoke.jpg');
  background-color: #666;
  -webkit-background-blend-mode: overlay;
  background-blend-mode: overlay;
  background-size: 100%;
  animation: movedeath 12s infinite linear;
}

.death:before {
  right: 99.5%;
}
.black-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-background-blend-mode: overlay;
  background-blend-mode: overlay;
  visibility: hidden;
  animation: disappear 2s linear;
  z-index: 9999;
}
.smoke-screen:before,
.smoke-screen:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url('../images/animations/smoke.jpg');
  background-color: #666;
  -webkit-background-blend-mode: overlay;
  background-blend-mode: overlay;
  background-size: 100%;
  animation: moveload 12s infinite linear;
}

.move-debug:hover {
  opacity: 0.5;
}
.sun-holder * {
  height: 100%;
  position: absolute;
}
.sun-holder *:before,
.sun-holder *:after {
  content: "";
  position: absolute;
}
.top {
  width: 100vw;
  background: -webkit-gradient(linear, left top, left bottom, from(#e74d5c), to(#f3774d));
  background: linear-gradient(to bottom, #e74d5c 0%, #f3774d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e74d5c', endColorstr='#f3774d', GradientType=0 );
  top: 0;
  left: 0;
  z-index: 1;
}
.debug-turn:hover {
  background-color: orangered;
  color: white;
}
.debug-turn,
.debug-reset,
.debug-turn-2 {
  cursor: pointer;
  z-index: 999;
  color: rgb(87, 34, 34);
  font-size: 20px;
  font-weight: bold;
  height: 30px;
  padding: 20px;
  line-height: 0px;
  background-color: orange;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 12px;
  text-align: center;
}
.debug-reset {
  color: white;
  right: 150px;
  background-color: #004996;
}
.debug-reset:hover {
  background-color: rgb(31, 104, 199);
}
.debug-turn-2 {
  right: 230px;
  background-color: rgb(13, 146, 13);
}
.stars {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  z-index: -1;
}
.stars > * {
  border-radius: 50px;
  background-color: #fca054;
  -webkit-animation: twinkle 5s infinite;
  animation: twinkle 5s infinite;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.stars .star:nth-of-type(1) {
  top: 292px;
  left: 141px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(2) {
  top: 390px;
  left: 452px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(3) {
  top: 348px;
  left: 409px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.stars .star:nth-of-type(4) {
  top: 107px;
  left: 1061px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(5) {
  top: 198px;
  left: 87px;
  width: 6px;
  height: 6px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(6) {
  top: 316px;
  left: 1069px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(7) {
  top: 273px;
  left: 522px;
  width: 6px;
  height: 6px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.stars .star:nth-of-type(8) {
  top: 356px;
  left: 1118px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(9) {
  top: 228px;
  left: 819px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(10) {
  top: 62px;
  left: 18px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(11) {
  top: 337px;
  left: 154px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(12) {
  top: 379px;
  left: 249px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.stars .star:nth-of-type(13) {
  top: 263px;
  left: 799px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(14) {
  top: 204px;
  left: 19px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(15) {
  top: 34px;
  left: 791px;
  width: 6px;
  height: 6px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(16) {
  top: 17px;
  left: 924px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(17) {
  top: 159px;
  left: 183px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(18) {
  top: 96px;
  left: 107px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(19) {
  top: 135px;
  left: 1076px;
  width: 6px;
  height: 6px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(20) {
  top: 305px;
  left: 728px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.stars .star:nth-of-type(21) {
  top: 218px;
  right: 909px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(22) {
  top: 104px;
  right: 934px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(23) {
  top: 255px;
  right: 726px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(24) {
  top: 53px;
  right: 1172px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(25) {
  top: 159px;
  right: 1004px;
  width: 3px;
  height: 3px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(26) {
  top: 58px;
  right: 805px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(27) {
  top: 348px;
  right: 637px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(28) {
  top: 264px;
  right: 450px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.stars .star:nth-of-type(29) {
  top: 373px;
  right: 817px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.stars .star:nth-of-type(30) {
  top: 195px;
  right: 1084px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.stars .star:nth-of-type(31) {
  top: 374px;
  right: 568px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(32) {
  top: 193px;
  right: 1119px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(33) {
  top: 342px;
  right: 946px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(34) {
  top: 147px;
  right: 411px;
  width: 5px;
  height: 5px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(35) {
  top: 277px;
  right: 618px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.stars .star:nth-of-type(36) {
  top: 351px;
  right: 1076px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(37) {
  top: 315px;
  right: 170px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(38) {
  top: 320px;
  right: 1106px;
  width: 4px;
  height: 4px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(39) {
  top: 296px;
  right: 49px;
  width: 2px;
  height: 2px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.stars .star:nth-of-type(40) {
  top: 277px;
  right: 40px;
  width: 1px;
  height: 1px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes twinkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes twinkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.shooting-star {
  background-color: #ffb058;
  background: -webkit-gradient(linear, left top, right top, from(#ffb058), to(transparent));
  background: linear-gradient(to right, #ffb058 0%, transparent 100%);
  width: 100px;
  height: 10px;
  border-radius: 200px;
  right: 15%;
  top: 15%;
  -webkit-animation: shooting-star 5s infinite;
  animation: shooting-star 5s infinite;
}
.shooting-star2 {
  background-color: #ffb058;
  background: -webkit-gradient(linear, left top, right top, from(#ffb058), to(transparent));
  background: linear-gradient(to right, #ffb058 0%, transparent 100%);
  width: 100px;
  height: 10px;
  border-radius: 200px;
  left: 20%;
  top: 35%;
  -webkit-animation: shooting-star 4s infinite;
  animation: shooting-star 4s infinite;
}
.shooting-star3 {
  background-color: #ffb058;
  background: -webkit-gradient(linear, left top, right top, from(#ffb058), to(transparent));
  background: linear-gradient(to right, #ffb058 0%, transparent 100%);
  width: 100px;
  height: 10px;
  border-radius: 200px;
  left: 65%;
  top: 50%;
  -webkit-animation: shooting-star 7s infinite;
  animation: shooting-star 7s infinite;
}

@-webkit-keyframes shooting-star {
  0% {
    -webkit-transform: translate(0, 0) rotate(-30deg) scale(0.8);
    transform: translate(0, 0) rotate(-30deg) scale(0.8);
    opacity: 1;
  }
  25% {
    -webkit-transform: translate(-50px, 50px) rotate(-30deg) scale(0.8);
    transform: translate(-50px, 50px) rotate(-30deg) scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(-30deg) scale(0.8);
    transform: translate(0, 0) rotate(-30deg) scale(0.8);
    opacity: 0;
  }
}

@keyframes shooting-star {
  0% {
    -webkit-transform: translate(0, 0) rotate(-30deg) scale(0.8);
    transform: translate(0, 0) rotate(-30deg) scale(0.8);
    opacity: 1;
  }
  25% {
    -webkit-transform: translate(-50px, 50px) rotate(-30deg) scale(0.8);
    transform: translate(-50px, 50px) rotate(-30deg) scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(-30deg) scale(0.8);
    transform: translate(0, 0) rotate(-30deg) scale(0.8);
    opacity: 0;
  }
}
.sun {
  width: 220px;
  height: 220px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -170px;
  background-color: #ffb058;
  border-radius: 50%;
  -webkit-animation-name: glowing;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
}
.water-sun {
  width: 250px;
  height: 250px;
  left: -140px;
  /* right: 0; */
  margin: auto;
  top: -200px;
  background-color: rgba(221, 249, 246, 1);
  border-radius: 50%;
  -webkit-animation: waterglow ease 3s infinite;
}
@-webkit-keyframes glowing {
  from {
    background-color: #fea828;
    -webkit-box-shadow: 0px -10px 75px 49px #ffdd58;
  }
  50% {
    background-color: #fea828;
    -webkit-box-shadow: 0px -10px 75px 49px #fea828;
  }
  to {
    background-color: #fea828;
    -webkit-box-shadow: 0px -10px 75px 49px #ffdd58;
  }
}
@-webkit-keyframes waterglow {
  from {
    background-color: rgba(221, 249, 246, 0.4);
    -webkit-box-shadow: 0px 10px 75px 50px rgba(221, 249, 246, 0.4);
  }
  50% {
    background-color: rgba(221, 249, 246, 0.3);
    -webkit-box-shadow: 0px 10px 75px 50px rgba(221, 249, 246, 0.1);
  }
  to {
    background-color: rgba(221, 249, 246, 0.7);
    -webkit-box-shadow: 0px 10px 75px 50px rgba(221, 249, 246, 0.6);
  }
}
.sun-glow-1 {
  background-color: rgba(255, 176, 88, 0.3);
  height: 300px;
  width: 360px;
  border-radius: 50%;
  top: -40px;
  left: -70px;
  z-index: 2;
}

.sun-glow-2 {
  background-color: rgba(255, 176, 88, 0.2);
  height: 380px;
  width: 420px;
  border-radius: 50%;
  top: -80px;
  left: -100px;
  z-index: 1;
}

.sun-glow-3 {
  background-color: rgba(255, 176, 88, 0.1);
  height: 360px;
  width: 540px;
  border-radius: 50%;
  top: -120px;
  left: -160px;
  z-index: -1;
}
.water-glow-1 {
  background-color: rgba(221, 249, 246, 0.3);
  height: 320px;
  width: 320px;
  border-radius: 50%;
  top: -40px;
  left: -40px;
  z-index: 2;
}

.water-glow-2 {
  background-color: rgba(221, 249, 246, 0.2);
  height: 400px;
  width: 400px;
  border-radius: 50%;
  top: -80px;
  left: -80px;
  z-index: 1;
}

.water-glow-3 {
  background-color: rgba(221, 249, 246, 0.1);
  height: 460px;
  width: 480px;
  border-radius: 50%;
  top: -120px;
  left: -120px;
}

.pyramid {
  -webkit-clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  background: #e74d5c;
  background: -webkit-gradient(linear, left top, left bottom, from(#e54c5b), to(#f3784d));
  background: linear-gradient(to bottom, #e54c5b 0%, #f3784d 100%);
}

.pyramids {
  width: 100%;
  height: 100%;
  bottom: -1%;
  z-index: 2;
  opacity: 0.7;
}

.pyramid:nth-of-type(1) {
  bottom: 0;
  height: 160px;
  width: 350px;
  left: -5%;
}

.pyramid:nth-of-type(2) {
  bottom: 0;
  height: 125px;
  width: 210px;
  left: 17%;
}

.pyramid:nth-of-type(3) {
  bottom: 0;
  height: 130px;
  width: 350px;
  right: -5%;
}

.pyramid:nth-of-type(4) {
  bottom: 0;
  height: 95px;
  width: 210px;
  right: 16%;
}

.clouds {
  width: 100%;
  height: 100%;
  -webkit-animation: clouds 180s infinite linear;
  animation: clouds 180s infinite linear;
}
.clouds *,
.clouds *:after,
.clouds *:before {
  border-radius: 50px;
}

.some-player-animation {
  position: absolute !important;
  z-index: 999 !important;
}
p[id^="overtime-1-"] {
  font-size: 20px;
  margin-left: 46px;
  margin-top: 20px;
  position: absolute;
  z-index: 999;
}
p[id^="overtime-2-"] {
  font-size: 20px;
  margin-left: 30px;
  margin-top: 50px;
  position: absolute;
  z-index: 999;
}
p[id^="overtime-3-"] {
  font-size: 20px;
  margin-top: 39px;
  position: absolute;
  z-index: 999;
}
p[id^="overtime-4-"] {
  font-size: 20px;
  margin-left: 24px;
  margin-top: 30px;
  position: absolute;
  z-index: 999;
}

.cloud {
  background-color: rgba(255, 255, 255, 0.3);
  width: 75px;
  height: 15px;
  border-radius: 50px;
  z-index: 99;
}

.cloud:nth-of-type(1) {
  top: 25%;
  left: 10%;
}
.cloud:nth-of-type(1):after {
  background-color: rgba(255, 255, 255, 0.3);
  top: 150%;
  left: 10%;
  width: 40%;
  height: 100%;
}

.cloud:nth-of-type(2) {
  top: 12%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.cloud:nth-of-type(2):after {
  background-color: rgba(255, 255, 255, 0.2);
  top: 0%;
  left: -40%;
  width: 25%;
  height: 100%;
}
.cloud:nth-of-type(2):before {
  background-color: rgba(255, 255, 255, 0.2);
  top: 175%;
  left: -15%;
  width: 75%;
  height: 100%;
}

.cloud:nth-of-type(3) {
  right: 10%;
  top: 40%;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.3);
}
.cloud:nth-of-type(3):after {
  background-color: rgba(255, 255, 255, 0.3);
  top: 0%;
  right: -50%;
  width: 30%;
  height: 100%;
}

@-webkit-keyframes clouds {
  0% {
    -webkit-transform: translatex(0%);
    transform: translatex(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes clouds {
  0% {
    -webkit-transform: translatex(0%);
    transform: translatex(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.hill-bg-1,
.hill-bg-2,
.hill-fg-1,
.hill-fg-2,
.hill-fg-3 {
  position: absolute;
  z-index: 2;
  width: 80%;
  height: 75%;
  bottom: -71%;
  left: 0;
  background: #26314f;
  border-radius: 50%;
  opacity: 0.4;
}
.hill-bg-2 {
  bottom: -58%;
  left: 60%;
  height: 70%;
  width: 70%;
  opacity: 0.2;
}
.hill-fg-1,
.hill-fg-2,
.hill-fg-3 {
  background: #303c5d;
  bottom: -61%;
  left: 0%;
  width: 40%;
  height: 65%;
  opacity: 0.4;
}
.hill-fg-2 {
  bottom: -59%;
  left: 35%;
  width: 50%;
}
.hill-fg-3 {
  bottom: -59%;
  left: 65%;
}
.ocean-floor {
  position: absolute;
  top: 0px;
}
#submarine-svg {
  width: 100%;
}
.ocean-layer {
  position: absolute;
}
.bubble {
  width: 2vw;
  height: 2vw;
  background: rgba(131, 197, 217, 0.3);
  opacity: 0.5;
  border: 0.5px solid #fff;
  border-radius: 10vw;
  -moz-border-radius: 10vw;
  -webkit-border-radius: 10vw;
  position: absolute;
}

.x1 {
  left: 85vw;
  color: yellow;
  -webkit-transform: scale(0.65);
  -moz-transform: scale(0.65);
  -o-transform: scale(0.65);
  transform: scale(0.65);
  opacity: 0.2;
  -webkit-animation: moveBubbles 10.4s linear infinite;
  -moz-animation: moveBubbles 10.4s linear infinite;
  -o-animation: moveBubbles 10.4s linear infinite;
  animation: moveBubbles 10.4s linear infinite;
}

.x2 {
  left: 5vw;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.35;
  -webkit-animation: moveBubbles 14.5s linear infinite;
  -moz-animation: moveBubbles 14.5s linear infinite;
  -o-animation: moveBubbles 14.5s linear infinite;
  animation: moveBubbles 14.5s linear infinite;
}

.x3 {
  top: 100vh;
  left: 35vw;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0.3;
  -webkit-animation: moveBubbles 11.5s linear infinite;
  -moz-animation: moveBubbles 11.5s linear infinite;
  -o-animation: moveBubbles 11.5s linear infinite;
  animation: moveBubbles 11.5s linear infinite;
  animation-delay: 3.5s;
}

.x4 {
  top: 100vh;
  left: 47vw;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.35;
  -webkit-animation: moveBubbles 13.5s linear infinite;
  -moz-animation: moveBubbles 13.5s linear infinite;
  -o-animation: moveBubbles 13.5s linear infinite;
  animation: moveBubbles 13.5s linear infinite;
  animation-delay: 1s;
}

.x5 {
  left: 15vw;
  -webkit-transform: scale(0.45);
  -moz-transform: scale(0.45);
  -o-transform: scale(0.45);
  transform: scale(0.45);
  opacity: 0.3;
  -webkit-animation: moveBubbles 13s linear infinite;
  -moz-animation: moveBubbles 13s linear infinite;
  -o-animation: moveBubbles 13s linear infinite;
  animation: moveBubbles 13s linear infinite;
}

.x6 {
  left: 73vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.2;
  -webkit-animation: moveBubbles 6s linear infinite;
  -moz-animation: moveBubbles 6s linear infinite;
  -o-animation: moveBubbles 6s linear infinite;
  animation: moveBubbles 6s linear infinite;
}

.x7 {
  left: 67vw;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.24;
  -webkit-animation: moveBubbles 11s linear infinite;
  -moz-animation: moveBubbles 11s linear infinite;
  -o-animation: moveBubbles 11s linear infinite;
  animation: moveBubbles 11s linear infinite;
}

.x8 {
  top: 100vh;
  left: 56vw;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.3;
  -webkit-animation: moveBubbles 12.9s linear infinite;
  -moz-animation: moveBubbles 12.9s linear infinite;
  -o-animation: moveBubbles 12.9s linear infinite;
  animation: moveBubbles 12.9s linear infinite;
  animation-delay: 3s;
}

.x9 {
  top: 100vh;
  left: 20vw;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.3;
  -webkit-animation: moveBubbles 9.5s linear infinite;
  -moz-animation: moveBubbles 9.5s linear infinite;
  -o-animation: moveBubbles 9.5s linear infinite;
  animation: moveBubbles 9.5s linear infinite;
  animation-delay: 2s;
}

.x10 {
  left: 33vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.3;
  -webkit-animation: moveBubbles 11.3s linear infinite;
  -moz-animation: moveBubbles 11.3s linear infinite;
  -o-animation: moveBubbles 11.3s linear infinite;
  animation: moveBubbles 11.3s linear infinite;
}
.x11 {
  left: 28vw;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.25;
  -webkit-animation: moveBubbles 8.3s linear infinite;
  -moz-animation: moveBubbles 8.3s linear infinite;
  -o-animation: moveBubbles 8.3s linear infinite;
  animation: moveBubbles 8.3s linear infinite;
  animation-delay: 1s;
}
.x12 {
  left: 40vw;
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  transform: scale(0.35);
  opacity: 0.3;
  -webkit-animation: moveBubbles 8.8s linear infinite;
  -moz-animation: moveBubbles 8.8s linear infinite;
  -o-animation: moveBubbles 8.8s linear infinite;
  animation: moveBubbles 8.8s linear infinite;
}
.x12 {
  left: 45vw;
  -webkit-transform: scale(0.45);
  -moz-transform: scale(0.45);
  -o-transform: scale(0.45);
  transform: scale(0.45);
  opacity: 0.2;
  -webkit-animation: moveBubbles 9.8s linear infinite;
  -moz-animation: moveBubbles 9.8s linear infinite;
  -o-animation: moveBubbles 9.8s linear infinite;
  animation: moveBubbles 9.8s linear infinite;
}
.x13 {
  left: 88vw;
  -webkit-transform: scale(0.32);
  -moz-transform: scale(0.32);
  -o-transform: scale(0.32);
  transform: scale(0.32);
  opacity: 0.28;
  -webkit-animation: moveBubbles 7s linear infinite;
  -moz-animation: moveBubbles 7s linear infinite;
  -o-animation: moveBubbles 7s linear infinite;
  animation: moveBubbles 7s linear infinite;
  animation-delay: 2.5s;
}
.x14 {
  left: 63vw;
  -webkit-transform: scale(0.42);
  -moz-transform: scale(0.42);
  -o-transform: scale(0.42);
  transform: scale(0.42);
  opacity: 0.28;
  -webkit-animation: moveBubbles 11.2s linear infinite;
  -moz-animation: moveBubbles 11.2s linear infinite;
  -o-animation: moveBubbles 11.2s linear infinite;
  animation: moveBubbles 11.2s linear infinite;
}
.x15 {
  left: 79vw;
  -webkit-transform: scale(0.38);
  -moz-transform: scale(0.38);
  -o-transform: scale(0.38);
  transform: scale(0.38);
  opacity: 0.34;
  -webkit-animation: moveBubbles 8.4s linear infinite;
  -moz-animation: moveBubbles 8.4s linear infinite;
  -o-animation: moveBubbles 8.4s linear infinite;
  animation: moveBubbles 8.4s linear infinite;
}
.x15 {
  left: 91vw;
  -webkit-transform: scale(0.18);
  -moz-transform: scale(0.18);
  -o-transform: scale(0.18);
  transform: scale(0.18);
  opacity: 0.32;
  -webkit-animation: moveBubbles 5.8s linear infinite;
  -moz-animation: moveBubbles 5.8s linear infinite;
  -o-animation: moveBubbles 5.8s linear infinite;
  animation: moveBubbles 5.8s linear infinite;
  animation-delay: 2.8s;
}
.x16 {
  left: 11vw;
  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  transform: scale(0.25);
  opacity: 0.36;
  -webkit-animation: moveBubbles 10.1s linear infinite;
  -moz-animation: moveBubbles 10.1s linear infinite;
  -o-animation: moveBubbles 10.1s linear infinite;
  animation: moveBubbles 10.1s linear infinite;
  animation-delay: 0.8s;
}
.x17 {
  left: 15vw;
  -webkit-transform: scale(0.29);
  -moz-transform: scale(0.29);
  -o-transform: scale(0.29);
  transform: scale(0.29);
  opacity: 0.31;
  -webkit-animation: moveBubbles 6.1s linear infinite;
  -moz-animation: moveBubbles 6.1s linear infinite;
  -o-animation: moveBubbles 6.1s linear infinite;
  animation: moveBubbles 6.1s linear infinite;
}
.x18 {
  left: 21vw;
  -webkit-transform: scale(0.21);
  -moz-transform: scale(0.21);
  -o-transform: scale(0.21);
  transform: scale(0.21);
  opacity: 0.33;
  -webkit-animation: moveBubbles 5.3s linear infinite;
  -moz-animation: moveBubbles 5.3s linear infinite;
  -o-animation: moveBubbles 5.3s linear infinite;
  animation: moveBubbles 5.3s linear infinite;
}
.x19 {
  left: 60vw;
  -webkit-transform: scale(0.21);
  -moz-transform: scale(0.21);
  -o-transform: scale(0.21);
  transform: scale(0.21);
  opacity: 0.33;
  -webkit-animation: moveBubbles 4.7s linear infinite;
  -moz-animation: moveBubbles 4.7s linear infinite;
  -o-animation: moveBubbles 4.7s linear infinite;
  animation: moveBubbles 4.7s linear infinite;
}
.x20 {
  left: 98vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.22;
  -webkit-animation: moveBubbles 12.7s linear infinite;
  -moz-animation: moveBubbles 12.7s linear infinite;
  -o-animation: moveBubbles 12.7s linear infinite;
  animation: moveBubbles 12.7s linear infinite;
  animation-delay: 2.2s;
}
.x21 {
  left: 2vw;
  -webkit-transform: scale(0.29);
  -moz-transform: scale(0.29);
  -o-transform: scale(0.29);
  transform: scale(0.29);
  opacity: 0.24;
  -webkit-animation: moveBubbles 7.7s linear infinite;
  -moz-animation: moveBubbles 7.7s linear infinite;
  -o-animation: moveBubbles 7.7s linear infinite;
  animation: moveBubbles 7.7s linear infinite;
}
.x22 {
  left: 57vw;
  -webkit-transform: scale(0.37);
  -moz-transform: scale(0.37);
  -o-transform: scale(0.37);
  transform: scale(0.37);
  opacity: 0.31;
  -webkit-animation: moveBubbles 9s linear infinite;
  -moz-animation: moveBubbles 9s linear infinite;
  -o-animation: moveBubbles 9s linear infinite;
  animation: moveBubbles 9s linear infinite;
}
.x23 {
  left: 51vw;
  -webkit-transform: scale(0.33);
  -moz-transform: scale(0.33);
  -o-transform: scale(0.33);
  transform: scale(0.33);
  opacity: 0.33;
  -webkit-animation: moveBubbles 8s linear infinite;
  -moz-animation: moveBubbles 8s linear infinite;
  -o-animation: moveBubbles 8s linear infinite;
  animation: moveBubbles 8s linear infinite;
}
.x24 {
  left: 28vw;
  -webkit-transform: scale(0.23);
  -moz-transform: scale(0.23);
  -o-transform: scale(0.23);
  transform: scale(0.23);
  opacity: 0.4;
  -webkit-animation: moveBubbles 11.6s linear infinite;
  -moz-animation: moveBubbles 11.6s linear infinite;
  -o-animation: moveBubbles 11.6s linear infinite;
  animation: moveBubbles 11.6s linear infinite;
}
.x25 {
  left: 34vw;
  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  transform: scale(0.23);
  opacity: 0.35;
  -webkit-animation: moveBubbles 6.6s linear infinite;
  -moz-animation: moveBubbles 6.6s linear infinite;
  -o-animation: moveBubbles 6.6s linear infinite;
  animation: moveBubbles 6.6s linear infinite;
}
.x26 {
  left: 76vw;
  -webkit-transform: scale(0.27);
  -moz-transform: scale(0.27);
  -o-transform: scale(0.27);
  transform: scale(0.27);
  opacity: 0.31;
  -webkit-animation: moveBubbles 5.1s linear infinite;
  -moz-animation: moveBubbles 5.1s linear infinite;
  -o-animation: moveBubbles 5.1s linear infinite;
  animation: moveBubbles 5.1s linear infinite;
}
.x27 {
  left: 84vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.33;
  -webkit-animation: moveBubbles 5.3s linear infinite;
  -moz-animation: moveBubbles 5.3s linear infinite;
  -o-animation: moveBubbles 5.3s linear infinite;
  animation: moveBubbles 5.3s linear infinite;
}
.x28 {
  left: 1vw;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0.4;
  -webkit-animation: moveBubbles 10.9s linear infinite;
  -moz-animation: moveBubbles 10.9s linear infinite;
  -o-animation: moveBubbles 10.9s linear infinite;
  animation: moveBubbles 10.9s linear infinite;
  animation-delay: 2s;
}
.x29 {
  left: 78vw;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0.38;
  -webkit-animation: moveBubbles 9.9s linear infinite;
  -moz-animation: moveBubbles 9.9s linear infinite;
  -o-animation: moveBubbles 9.9s linear infinite;
  animation: moveBubbles 9.9s linear infinite;
}
.x30 {
  left: 49vw;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.2;
  -webkit-animation: moveBubbles 8.9s linear infinite;
  -moz-animation: moveBubbles 8.9s linear infinite;
  -o-animation: moveBubbles 8.9s linear infinite;
  animation: moveBubbles 8.9s linear infinite;
}
.x31 {
  left: 96vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.2;
  -webkit-animation: moveBubbles 4.9s linear infinite;
  -moz-animation: moveBubbles 4.9s linear infinite;
  -o-animation: moveBubbles 4.9s linear infinite;
  animation: moveBubbles 4.9s linear infinite;
}
.x32 {
  left: 73vw;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0.2;
  -webkit-animation: moveBubbles 2.5s linear infinite;
  -moz-animation: moveBubbles 2.5s linear infinite;
  -o-animation: moveBubbles 2.5s linear infinite;
  animation: moveBubbles 2.5s linear infinite;
}

.x33 {
  left: 67vw;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.24;
  -webkit-animation: moveBubbles 3s linear infinite;
  -moz-animation: moveBubbles 3s linear infinite;
  -o-animation: moveBubbles 3s linear infinite;
  animation: moveBubbles 3s linear infinite;
}

.x34 {
  left: 56vw;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.3;
  -webkit-animation: moveBubbles 1.9s linear infinite;
  -moz-animation: moveBubbles 1.9s linear infinite;
  -o-animation: moveBubbles 1.9s linear infinite;
  animation: moveBubbles 1.9s linear infinite;
}

@-webkit-keyframes moveBubbles {
  0% {
    top: 110vh;
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
  100% {
    top: -0vh;
    opacity: 0.4;
  }
}

@-moz-keyframes moveBubbles {
  0% {
    top: 110vh;
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    opacity: 0.4;
  }
}

@-o-keyframes moveBubbles {
  0% {
    top: 110vh;
    opacity: 0.3;
  }

  10% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    opacity: 0.4;
  }
}

@keyframes moveBubbles {
  0% {
    top: 110vh;
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    opacity: 0.3;
  }
}
.waters {
  background: linear-gradient(340deg, #004996, #21d1e6, #74f7ff, #ccfffe);
  overflow: hidden;
  height: 100vh;
  background-size: 250% 250%;
  -webkit-animation: OceanBlue 1s;
  -moz-animation: OceanBlue 1s;
  animation: OceanBlue 1s;
  animation-fill-mode: forwards;
  min-height: 760px;
}
@-webkit-keyframes OceanBlue {
  0% {
    background-position: 0% 2%;
  }
  100% {
    background-position: 100% 99%;
  }
}
@-moz-keyframes OceanBlue {
  0% {
    background-position: 0% 2%;
  }
  100% {
    background-position: 100% 99%;
  }
}
@keyframes OceanBlue {
  0% {
    background-position: 0% 2%;
  }
  100% {
    background-position: 100% 99%;
  }
}
.waters .h5 {
  color: rgba(72, 72, 72, 0.5);
}
.waters .attack-bp {
  color: #ccfffe;
}
/* //  LEAF CODE */

.leaf:before,
.leaf:after {
  position: absolute;
  content: "";
  left: 18px;
  top: 0;
  width: 30px;
  height: 30px;
  background: #a0de21;
  -moz-border-radius: 30px 0px;
  -webkit-border-radius: 30px 0px;
  border-radius: 30px 0px;
}
.bigLeafWrap {
  background-image: linear-gradient(to top, #96fbc4 0%, #f9f586 100%);
  height: 100vh;
  width: 100vw;
}
.leaf2 {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #a0de21;
  -moz-border-radius: 0px 30px;
  -webkit-border-radius: 0px 30px;
  border-radius: 0px 30px;
}
.leaf {
  position: relative;
}
.triple-death {
  z-index: 99;
}
.falling-skulls {
  position: absolute;
  width: 100%;
  height: 100%;
}
.skull-cont0 {
  position: absolute;
  bottom: 2px;
  left: 180px;
  opacity: 0.8;
  transform: rotate(-23deg);
}
.skull-cont1 {
  position: absolute;
  bottom: 3px;
  left: 10px;
  opacity: 0.9;
}
.skull-cont2 {
  position: absolute;
  bottom: 4px;
  left: 40px;
  transform: rotate(60deg);
  opacity: 0.9;
}
.skull-cont3 {
  position: absolute;
  bottom: 31px;
  left: 25px;
  opacity: 0.9;
  transform: rotate(-10deg);
}
.skull-cont4 {
  position: absolute;
  bottom: 5px;
  left: 70px;
  transform: rotate(-10deg);
  opacity: 0.9;
}
.skull-cont5 {
  position: absolute;
  bottom: 31px;
  left: 75px;
  transform: rotate(-60deg);
  opacity: 0.7;
}
.skull-cont6 {
  position: absolute;
  bottom: 5px;
  left: 100px;
  opacity: 0.9;
}
.skull-cont7 {
  position: absolute;
  bottom: 25px;
  left: -4px;
  opacity: 0.9;
  transform: rotate(130deg);
}
.skull-cont8 {
  position: absolute;
  bottom: 5px;
  left: 100px;
  opacity: 0.9;
}
.skull-cont9 {
  position: absolute;
  bottom: 0px;
  left: 128px;
  opacity: 0.9;
  transform: rotate(-200deg);
}
.skull-cont10 {
  position: absolute;
  bottom: 85px;
  left: 0px;
  opacity: 0.9;
  transform: rotate(8deg);
}
.skull-cont11 {
  position: absolute;
  bottom: 55px;
  left: 0px;
  opacity: 0.9;
}
.skull-cont12 {
  position: absolute;
  bottom: 115px;
  left: 0px;
  opacity: 0.9;
}
.skull-cont13 {
  position: absolute;
  bottom: 172px;
  left: -5px;
  opacity: 0.8;
  transform: rotate(8deg);
}
.skull-cont14 {
  position: absolute;
  bottom: 64px;
  left: 26px;
  opacity: 0.9;
  transform: rotate(-23deg);
}
.skull-cont15 {
  position: absolute;
  bottom: 142px;
  left: 0px;
  opacity: 0.9;
}
.skull-cont16 {
  position: absolute;
  bottom: 48px;
  left: 54px;
  opacity: 0.9;
  transform: rotate(23deg);
}
.skull-cont17 {
  position: absolute;
  bottom: 95px;
  left: 25px;
  opacity: 0.9;
}
.skull-cont18 {
  position: absolute;
  bottom: 3px;
  left: 155px;
  opacity: 0.9;
}
.skull-cont19 {
  position: absolute;
  bottom: 3px;
  right: 10px;
  opacity: 0.9;
}
.skull-cont20 {
  position: absolute;
  bottom: 4px;
  right: 40px;
  transform: rotate(60deg);
  opacity: 0.9;
}
.skull-cont21 {
  position: absolute;
  bottom: 31px;
  right: 25px;
  opacity: 0.9;
  transform: rotate(-10deg);
}
.skull-cont22 {
  position: absolute;
  bottom: 5px;
  right: 70px;
  transform: rotate(-10deg);
  opacity: 0.9;
}
.skull-cont23 {
  position: absolute;
  bottom: 31px;
  right: 75px;
  transform: rotate(-60deg);
  opacity: 0.9;
}
.skull-cont24 {
  position: absolute;
  bottom: 5px;
  right: 100px;
  opacity: 0.9;
}
.skull-cont25 {
  position: absolute;
  bottom: 25px;
  right: -4px;
  opacity: 0.9;
  transform: rotate(130deg);
}
.skull-cont26 {
  position: absolute;
  bottom: 5px;
  right: 100px;
  opacity: 0.9;
}
.skull-cont27 {
  position: absolute;
  bottom: 1px;
  right: 128px;
  opacity: 0.9;
  transform: rotate(-200deg);
}
.skull-cont28 {
  position: absolute;
  bottom: 85px;
  right: 0px;
  opacity: 0.9;
  transform: rotate(8deg);
}
.skull-cont29 {
  position: absolute;
  bottom: 55px;
  right: 0px;
  opacity: 0.9;
}
.skull-cont30 {
  position: absolute;
  bottom: 115px;
  right: 0px;
  opacity: 0.9;
}
.skull-cont31 {
  position: absolute;
  bottom: 176px;
  right: -5px;
  opacity: 0.8;
  transform: rotate(8deg);
}
.skull-cont32 {
  position: absolute;
  bottom: 64px;
  right: 26px;
  opacity: 0.9;
  transform: rotate(-23deg);
}
.skull-cont33 {
  position: absolute;
  bottom: 145px;
  right: -2px;
  opacity: 0.9;
  transform: rotate(23deg);
}
.skull-cont34 {
  position: absolute;
  bottom: 48px;
  right: 48px;
  opacity: 0.8;
  transform: rotate(23deg);
}
.skull-cont35 {
  position: absolute;
  bottom: 95px;
  right: 25px;
  opacity: 0.9;
}
.skull-cont36 {
  position: absolute;
  bottom: 3px;
  right: 155px;
  opacity: 0.8;
}
.skull-cont37 {
  position: absolute;
  bottom: 2px;
  right: 180px;
  opacity: 0.8;
  transform: rotate(23deg);
}
.left-skulls {
  opacity: 0.4;
}
.right-skulls {
  opacity: 0.4;
}
.bigskull {
  position: relative;
  background: transparent;
  box-sizing: content-box;
  opacity: 0.1;
  width: 30px;
  height: 30px;
  border: 32px solid #fff;
  border-top: 40px solid transparent;
  border-bottom: 22px solid white;
  border-radius: 30px 30px 15px 15px;
  box-shadow: -40px 45px 0 -40px white, 40px 45px 0 -40px white, -40px 51px 0 -40px white, -24px 51px 0 -40px white,
    -8px 51px 0 -40px white, 8px 51px 0 -40px white, 24px 51px 0 -40px white, 40px 51px 0 -40px white,
    -40px 65px 0 -40px white, -24px 65px 0 -40px white, -8px 65px 0 -40px white, 8px 65px 0 -40px white,
    24px 65px 0 -40px white, 40px 65px 0 -40px white, 0px 24px 0 0px #333, 0px 40px 0 0px white;
}

.bigskull:before {
  position: absolute;
  top: -60px;
  left: -51px;
  width: 0px;
  height: 0px;
  border: 66px solid white;
  border-bottom: 66px solid transparent;
  border-radius: 66px;
  content: "";
}

.bigskull:after {
  position: absolute;
  top: -13px;
  left: -33px;
  width: 35px;
  height: 35px;
  background: #e90c38;
  border-radius: 20px;
  box-shadow: 61px 0 0 #e90c38, 4px -1px 0 9px white, -2px 4px 0 11px white, 58px -1px 0 9px white,
    64px 4px 0 11px white;
  -webkit-animation: redglow ease 3s infinite;

  content: "";
}
.bigskull2 {
  position: relative;
  background: transparent;
  box-sizing: content-box;
  opacity: 0.8;
  width: 30px;
  height: 30px;
  border: 32px solid #fff;
  border-top: 40px solid transparent;
  border-bottom: 22px solid white;
  border-radius: 30px 30px 15px 15px;
  z-index: 999;
  box-shadow: -40px 45px 0 -40px white, 40px 45px 0 -40px white, -40px 51px 0 -40px white, -24px 51px 0 -40px white,
    -8px 51px 0 -40px white, 8px 51px 0 -40px white, 24px 51px 0 -40px white, 40px 51px 0 -40px white,
    -40px 65px 0 -40px white, -24px 65px 0 -40px white, -8px 65px 0 -40px white, 8px 65px 0 -40px white,
    24px 65px 0 -40px white, 40px 65px 0 -40px white, 0px 24px 0 0px #333, 0px 40px 0 0px white;
}

.bigskull2:before {
  position: absolute;
  top: -60px;
  left: -51px;
  width: 0px;
  height: 0px;
  border: 66px solid white;
  border-bottom: 66px solid transparent;
  border-radius: 66px;
  content: "";
}

.bigskull2:after {
  position: absolute;
  top: -13px;
  left: -33px;
  width: 35px;
  height: 35px;
  background: #e90c38;
  border-radius: 20px;
  box-shadow: 61px 0 0 #e90c38, 4px -1px 0 9px white, -2px 4px 0 11px white, 58px -1px 0 9px white,
    64px 4px 0 11px white;
  -webkit-animation: redglow ease 3s infinite;

  content: "";
}
.light-skull {
  opacity: 0.15;
}
.skull {
  position: relative;
  box-sizing: content-box;
  background: transparent;
  width: 6px;
  height: 6px;
  border: 6px solid white;
  border-top: 8px solid transparent;
  border-bottom: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 30px 30px 15px 15px;
  box-shadow: -8px 9px 0 -8px rgba(255, 255, 255, 0.8), 8px 9px 0 -8px rgba(255, 255, 255, 0.8),
    -8px 10px 0 -8px rgba(255, 255, 255, 0.8), -5px 10px 0 -8px rgba(255, 255, 255, 0.8),
    -1px 10px 0 -8px rgba(255, 255, 255, 0.8), 1px 10px 0 -8px rgba(255, 255, 255, 0.8),
    5px 10px 0 -8px rgba(255, 255, 255, 0.8), 8px 10px 0 -8px rgba(255, 255, 255, 0.8),
    -8px 13px 0 -8px rgba(255, 255, 255, 0.8), -5px 13px 0 -8px rgba(255, 255, 255, 0.8),
    -1px 13px 0 -8px rgba(255, 255, 255, 0.8), 1px 13px 0 -8px rgba(255, 255, 255, 0.8),
    5px 13px 0 -8px rgba(255, 255, 255, 0.8), 8px 13px 0 -8px rgba(255, 255, 255, 0.8), 0px 5px 0 0px #000,
    0px 8px 0 0px rgba(255, 255, 255, 0.8);
}

.skull:before {
  position: absolute;
  top: -12px;
  left: -10px;
  width: 0px;
  height: 0px;
  border: 13px solid rgba(255, 255, 255, 0.8);
  border-bottom: 13px solid transparent;
  border-radius: 13px;
  content: "";
}

.skull:after {
  position: absolute;
  top: -2px;
  left: -6px;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 20px;
  box-shadow: 12px 0 0 #000, 1px -1px 0 2px rgba(255, 255, 255, 0.8), -1px 4px 0 2px rgba(255, 255, 255, 0.8),
    11px -1px 0 2px rgba(255, 255, 255, 0.8), 13px 1px 0 2px rgba(255, 255, 255, 0.8);
  content: "";
}

ul.trees2 {
  position: absolute;
  bottom: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
}
ul.trees2 li {
  width: 0px;
  display: block;
  height: 0px;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 400px solid #68ad87;
  border-top: 0px solid transparent;
  position: absolute;
  bottom: -50px;
  animation: wind 10s ease-in-out infinite;
}
@keyframes wind {
  50% {
    transform: rotate(10deg);
  }
}

ul.trees2 li:nth-child(1) {
  left: -82px;
  bottom: -30px;
}
ul.trees2 li:nth-child(2) {
  left: -50px;
}
ul.trees2 li:nth-child(3) {
  left: 40px;
  bottom: -120px;
}
ul.trees2 li:nth-child(4) {
  left: 90px;
  bottom: -140px;
}
ul.trees2 li:nth-child(5) {
  left: 120px;
  bottom: -90px;
}
ul.trees2 li:nth-child(6) {
  left: 190px;
  bottom: -160px;
}
ul.trees2 li:nth-child(7) {
  left: 270px;
  bottom: -190px;
}
ul.trees2 li:nth-child(8) {
  left: 320px;
  bottom: -220px;
}
ul.trees2 li:nth-child(9) {
  right: -120px;
}
ul.trees2 li:nth-child(10) {
  right: -20px;
  bottom: 0px;
}
ul.trees2 li:nth-child(11) {
  right: 44px;
  bottom: -60px;
}
ul.trees2 li:nth-child(12) {
  right: 100px;
  bottom: -80px;
}
ul.trees2 li:nth-child(13) {
  right: 191px;
  bottom: -150px;
}
ul.trees2 li:nth-child(14) {
  right: 220px;
  bottom: -160px;
}
ul.trees2 li:nth-child(15) {
  right: 270px;
  bottom: -220px;
}
ul.trees2 li:nth-child(16) {
  right: 340px;
  bottom: -250px;
}

/*-- trees front --*/
ul.trees1 {
  position: absolute;
  bottom: -140px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
}
ul.trees1 li {
  width: 0px;
  display: block;
  height: 0px;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 400px solid #51876a;
  border-top: 0px solid transparent;
  position: absolute;
  bottom: -50px;
  animation: windys 20s ease-in-out infinite;
  animation-delay: 3.5s;
}
@keyframes windys {
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}

ul.trees1 li:nth-child(1) {
  left: -82px;
  bottom: -30px;
}
ul.trees1 li:nth-child(2) {
  left: -50px;
}
ul.trees1 li:nth-child(3) {
  left: 40px;
  bottom: -120px;
}
ul.trees1 li:nth-child(4) {
  left: 90px;
  bottom: -140px;
}
ul.trees1 li:nth-child(5) {
  left: 120px;
  bottom: -90px;
}
ul.trees1 li:nth-child(6) {
  left: 190px;
  bottom: -160px;
}
ul.trees1 li:nth-child(7) {
  left: 270px;
  bottom: -190px;
}
ul.trees1 li:nth-child(8) {
  left: 320px;
  bottom: -220px;
}
ul.trees1 li:nth-child(9) {
  right: -120px;
}
ul.trees1 li:nth-child(10) {
  right: 0px;
  bottom: -10px;
}
ul.trees1 li:nth-child(11) {
  right: 94px;
  bottom: -140px;
}
ul.trees1 li:nth-child(12) {
  right: 150px;
  bottom: -90px;
}
ul.trees1 li:nth-child(13) {
  right: 211px;
  bottom: -230px;
}
ul.trees1 li:nth-child(14) {
  right: 240px;
  bottom: -190px;
}
ul.trees1 li:nth-child(15) {
  right: 302px;
  bottom: -280px;
}
ul.trees1 li:nth-child(16) {
  right: 340px;
  bottom: -250px;
}

/*-- trees back --*/
ul.trees3 {
  position: absolute;
  bottom: -140px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;

  left: 250px;
}
ul.trees3 li {
  width: 0px;
  display: block;
  height: 0px;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 400px solid #7fd4a5;
  border-top: 0px solid transparent;
  position: absolute;
  bottom: -50px;
  animation: windy 10s ease-in-out infinite;
  animation-delay: 1.5s;
}
@keyframes windy {
  50% {
    transform: rotate(10deg);
  }
}

ul.trees3 li:nth-child(1) {
  left: -82px;
  bottom: -30px;
}
ul.trees3 li:nth-child(2) {
  left: -19px;
  bottom: -63px;
}
ul.trees3 li:nth-child(3) {
  left: 66px;
  bottom: -100px;
}
ul.trees3 li:nth-child(4) {
  left: 90px;
  bottom: -140px;
}
ul.trees3 li:nth-child(5) {
  left: 120px;
  bottom: -130px;
}
ul.trees3 li:nth-child(6) {
  left: 153px;
  bottom: -150px;
}
ul.trees3 li:nth-child(7) {
  left: 191px;
  bottom: -179px;
}
ul.trees3 li:nth-child(8) {
  left: 253px;
  bottom: -220px;
}
ul.trees3 li:nth-child(9) {
  right: 370px;
  bottom: 30px;
}
ul.trees3 li:nth-child(10) {
  right: 420px;
  bottom: 10px;
}
ul.trees3 li:nth-child(11) {
  right: 504px;
  bottom: -60px;
}
ul.trees3 li:nth-child(12) {
  right: 550px;
  bottom: -90px;
}
ul.trees3 li:nth-child(13) {
  right: 627px;
  bottom: -140px;
}
ul.trees3 li:nth-child(14) {
  right: 670px;
  bottom: -131px;
}
ul.trees3 li:nth-child(15) {
  right: 696px;
  bottom: -172px;
}
ul.trees3 li:nth-child(16) {
  right: 730px;
  bottom: -190px;
}

@media screen and (min-height: 0px) and (max-height: 600px) {
  ul.trees1 {
    bottom: -270px;
  }
  ul.trees2 {
    bottom: -170px;
  }
  ul.trees3 {
    bottom: -300px;
  }
}

/* END FOR OLD BACKGROUNDS CSS */

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 81%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 81%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 81%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 81%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 81%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0% 81%;
  }
}

#normalstar {
  width: 5px;
  height: 5px;
  display: block;
  background: transparent;
  border-radius: 50%;
  -webkit-box-shadow: 52px 978px #c00, 1785px 1521px #fff, 1677px 1988px #fff, 348px 916px #fff, 1347px 280px #fff,
    1179px 1851px #fff, 1989px 1069px #fff, 1583px 1910px #fff, 1446px 693px #fff, 898px 985px #fff;
  box-shadow: 52px 978px #c00, 1785px 1521px #fff, 1677px 1988px #fff, 348px 916px #fff, 1347px 280px #fff,
    1179px 1851px #fff, 1989px 1069px #fff, 1583px 1910px #fff, 1446px 693px #fff, 898px 985px #fff;
  -webkit-animation: animStar 240s linear infinite;
  animation: animStar 240s linear infinite;
}
#normalstar::after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: transparent;
  -webkit-box-shadow: 52px 978px #c00, 1785px 1521px #fff, 1677px 1988px #fff, 348px 916px #fff, 1347px 280px #fff,
    1179px 1851px #fff, 1989px 1069px #fff, 1583px 1910px #fff, 1446px 693px #fff, 898px 985px #fff;
  box-shadow: 52px 978px #c00, 1785px 1521px #fff, 1677px 1988px #fff, 348px 916px #fff, 1347px 280px #fff,
    1179px 1851px #fff, 1989px 1069px #fff, 1583px 1910px #fff, 1446px 693px #fff, 898px 985px #fff;
}

#star {
  width: 1px;
  height: 1px;
  display: block;
  background: transparent;
  border-radius: 50%;
  -webkit-box-shadow: 122px 389px #c00, 891px 83px #fff, 1005px 1066px #fff, 1256px 43px #fff, 1165px 1728px #fff,
    1189px 1872px #fff, 1049px 143px #fff, 1534px 1976px #fff, 1608px 1479px #fff, 618px 434px #fff, 315px 423px #fff,
    1141px 83px #fff, 1397px 3px #fff, 1668px 1270px #fff, 1990px 833px #fff, 1430px 577px #fff, 832px 1917px #fff,
    99px 1448px #fff, 32px 1325px #fff, 980px 805px #fff, 20px 828px #fff, 1962px 943px #fff, 1996px 897px #fff,
    1874px 1889px #fff, 1820px 313px #fff, 1255px 1080px #fff, 1064px 72px #fff, 1816px 1671px #fff, 11px 1694px #fff,
    850px 1348px #fff, 219px 906px #fff, 703px 523px #fff, 96px 521px #fff, 470px 1329px #fff, 1300px 123px #fff,
    733px 620px #fff, 590px 46px #fff, 92px 1003px #fff, 948px 1955px #fff, 1410px 541px #fff, 400px 600px #fff,
    1624px 1761px #fff, 1708px 1777px #fff, 640px 1624px #fff, 821px 1559px #fff, 1268px 888px #fff, 1493px 1696px #fff,
    1726px 1658px #fff, 900px 1635px #fff, 720px 1463px #fff, 598px 1350px #fff, 1286px 1705px #fff, 364px 1462px #fff,
    1335px 171px #fff, 1170px 1066px #fff, 590px 1550px #fff, 704px 1539px #fff, 1921px 230px #fff, 1466px 128px #fff,
    842px 812px #fff, 885px 801px #fff, 471px 1220px #fff, 1828px 265px #fff, 1195px 61px #fff, 1563px 81px #fff,
    1110px 1984px #fff, 603px 1370px #fff, 426px 1953px #fff, 578px 1488px #fff, 962px 1362px #fff, 512px 1972px #fff,
    36px 1994px #fff, 425px 1515px #fff, 1716px 1189px #fff, 1401px 1160px #fff, 1706px 268px #fff, 242px 1534px #fff,
    1140px 1920px #fff, 842px 1521px #fff, 283px 1734px #fff, 589px 1251px #fff, 824px 1148px #fff, 1626px 122px #fff,
    1735px 948px #fff, 477px 265px #fff, 1534px 1000px #fff, 1688px 586px #fff, 872px 394px #fff, 682px 42px #fff,
    1734px 623px #fff, 1316px 1041px #fff, 32px 1653px #fff, 1347px 843px #fff, 37px 1831px #fff, 422px 1895px #fff,
    945px 1656px #fff, 1852px 1894px #fff, 1245px 334px #fff, 1829px 1400px #fff, 807px 1090px #fff, 1003px 460px #fff,
    584px 1582px #fff, 669px 546px #fff, 1059px 528px #fff, 89px 143px #fff, 431px 260px #fff, 97px 1926px #fff,
    491px 703px #fff, 820px 1827px #fff, 1712px 897px #fff, 1610px 1439px #fff, 1599px 1849px #fff, 291px 914px #fff,
    1394px 1718px #fff, 444px 56px #fff, 925px 37px #fff, 484px 1491px #fff, 1881px 1694px #fff, 430px 1818px #fff,
    272px 871px #fff, 1982px 484px #fff, 632px 1388px #fff, 1779px 1911px #fff, 1813px 1592px #fff, 644px 1598px #fff,
    27px 1520px #fff, 1066px 1925px #fff, 1264px 1320px #fff, 36px 1449px #fff, 1712px 1815px #fff, 256px 1164px #fff,
    728px 143px #fff, 637px 788px #fff, 703px 1696px #fff, 808px 1506px #fff, 1952px 66px #fff, 1686px 984px #fff,
    630px 389px #fff, 3px 905px #fff, 1563px 110px #fff, 1017px 930px #fff, 1863px 468px #fff, 1475px 436px #fff,
    1127px 1597px #fff, 394px 386px #fff, 1198px 490px #fff, 1222px 1499px #fff, 1522px 266px #fff, 1073px 1781px #fff,
    1293px 1387px #fff, 849px 1341px #fff, 1435px 1600px #fff, 286px 1578px #fff, 569px 1968px #fff, 779px 1127px #fff,
    1669px 1335px #fff, 575px 1263px #fff, 1536px 1166px #fff, 1033px 1487px #fff, 1165px 704px #fff, 834px 1310px #fff,
    741px 1908px #fff, 441px 878px #fff, 1199px 147px #fff, 1492px 104px #fff, 55px 583px #fff, 1830px 1259px #fff,
    1705px 1444px #fff, 790px 1719px #fff, 776px 1357px #fff, 1873px 1460px #fff, 1015px 1649px #fff, 1126px 1789px #fff,
    1986px 1680px #fff, 1946px 677px #fff, 1977px 1351px #fff, 803px 1156px #fff, 668px 1397px #fff, 999px 609px #fff,
    853px 1241px #fff, 224px 329px #fff, 1950px 385px #fff, 1803px 1020px #fff, 277px 1201px #fff, 428px 1782px #fff,
    746px 764px #fff, 406px 764px #fff, 37px 105px #fff, 169px 1102px #fff, 557px 251px #fff, 1517px 490px #fff,
    1468px 788px #fff, 173px 358px #fff, 484px 800px #fff, 1216px 409px #fff, 1635px 1940px #fff, 1514px 1066px #fff,
    371px 1658px #fff, 1520px 82px #fff, 692px 370px #fff, 569px 541px #fff, 1759px 795px #fff, 1919px 699px #fff,
    1271px 1328px #fff, 1682px 857px #fff, 1478px 1217px #fff, 282px 1548px #fff, 163px 1248px #fff, 1478px 272px #fff,
    87px 1878px #fff, 1049px 1048px #fff, 403px 1193px #fff, 589px 1178px #fff, 1887px 1984px #fff, 1763px 734px #fff,
    1028px 1743px #fff, 1663px 1145px #fff, 380px 1867px #fff, 839px 1817px #fff, 1370px 1766px #fff, 495px 627px #fff,
    477px 421px #fff, 1374px 43px #fff, 133px 351px #fff, 1167px 589px #fff, 77px 1894px #fff, 977px 1102px #fff,
    745px 143px #fff, 460px 175px #fff, 775px 1177px #fff, 304px 1302px #fff, 284px 19px #fff, 1546px 1422px #fff,
    1355px 1980px #fff, 387px 1279px #fff, 1077px 1550px #fff, 1696px 185px #fff, 1958px 1393px #fff, 71px 1751px #fff,
    4px 1016px #fff, 997px 1204px #fff, 762px 441px #fff, 1766px 1091px #fff, 200px 1578px #fff, 675px 1455px #fff,
    367px 1899px #fff, 1172px 1398px #fff, 1858px 1149px #fff, 709px 595px #fff, 1304px 1996px #fff, 970px 798px #fff,
    1342px 1587px #fff, 1578px 11px #fff, 701px 1490px #fff, 1795px 302px #fff, 1035px 959px #fff, 443px 32px #fff,
    1027px 308px #fff, 1736px 634px #fff, 1152px 940px #fff, 18px 1957px #fff, 1289px 1727px #fff, 1235px 1799px #fff,
    848px 657px #fff, 1718px 1909px #fff, 1787px 1689px #fff, 1533px 828px #fff, 429px 1282px #fff, 1675px 1543px #fff,
    1061px 1186px #fff, 1412px 604px #fff, 1644px 1967px #fff, 1124px 942px #fff, 736px 764px #fff, 78px 263px #fff,
    1352px 1462px #fff, 1465px 676px #fff, 49px 602px #fff, 178px 1997px #fff, 869px 1501px #fff, 1245px 1831px #fff,
    574px 44px #fff, 789px 1130px #fff, 1260px 1459px #fff, 1531px 1984px #fff, 781px 1294px #fff, 748px 854px #fff,
    132px 1807px #fff, 1019px 752px #fff, 864px 1281px #fff, 1054px 936px #fff, 1866px 715px #fff, 1053px 644px #fff,
    721px 94px #fff, 875px 1059px #fff, 1370px 333px #fff, 523px 823px #fff, 1799px 1762px #fff, 1145px 795px #fff,
    480px 1192px #fff, 1346px 1196px #fff, 384px 534px #fff, 391px 920px #fff, 1360px 1005px #fff, 1556px 1327px #fff,
    1024px 405px #fff, 1802px 1330px #fff, 66px 633px #fff, 77px 1091px #fff, 1001px 214px #fff, 541px 57px #fff,
    132px 1186px #fff, 840px 319px #fff, 978px 606px #fff, 599px 1546px #fff, 774px 1432px #fff, 1534px 1774px #fff,
    322px 1940px #fff, 1557px 620px #fff, 1409px 731px #fff, 7px 581px #fff, 1659px 367px #fff, 684px 211px #fff,
    368px 1512px #fff, 356px 836px #fff, 911px 492px #fff, 1936px 1541px #fff, 1762px 827px #fff, 941px 269px #fff,
    391px 570px #fff, 1387px 1110px #fff, 1444px 1181px #fff, 771px 1311px #fff, 1255px 1313px #fff, 1019px 118px #fff,
    304px 610px #fff, 746px 1684px #fff, 1933px 1264px #fff, 939px 966px #fff, 538px 1913px #fff, 1165px 887px #fff,
    1873px 876px #fff, 781px 1325px #fff, 793px 937px #fff, 1723px 291px #fff, 588px 926px #fff, 120px 594px #fff,
    479px 1123px #fff, 867px 124px #fff, 1220px 1756px #fff, 819px 587px #fff, 159px 1584px #fff, 268px 407px #fff,
    1909px 1802px #fff, 790px 1298px #fff, 1407px 1766px #fff, 1635px 77px #fff, 1801px 252px #fff, 1397px 1715px #fff,
    1100px 792px #fff, 632px 1247px #fff, 476px 1160px #fff, 198px 1316px #fff, 666px 590px #fff, 171px 1796px #fff,
    968px 649px #fff, 641px 809px #fff, 1925px 1518px #fff, 1306px 1646px #fff, 612px 1823px #fff, 928px 598px #fff,
    28px 311px #fff, 808px 1292px #fff, 1358px 85px #fff, 1651px 809px #fff, 1053px 884px #fff, 1289px 1392px #fff,
    745px 333px #fff, 822px 155px #fff, 1595px 382px #fff, 229px 308px #fff, 761px 46px #fff, 1820px 387px #fff,
    1330px 1635px #fff, 605px 1754px #fff, 1104px 1170px #fff, 1600px 1314px #fff, 1766px 1924px #fff, 859px 2000px #fff,
    47px 502px #fff, 1178px 218px #fff, 386px 664px #fff, 254px 875px #fff, 322px 641px #fff, 1608px 1578px #fff,
    631px 416px #fff, 38px 1485px #fff, 999px 1390px #fff, 1957px 378px #fff, 1983px 885px #fff, 1098px 222px #fff,
    1367px 124px #fff, 1370px 773px #fff, 169px 1131px #fff, 983px 973px #fff, 258px 547px #fff, 1540px 35px #fff,
    1582px 1301px #fff, 93px 1625px #fff, 567px 503px #fff, 1523px 1102px #fff, 1992px 394px #fff, 579px 669px #fff,
    1466px 537px #fff, 1202px 1353px #fff, 67px 1018px #fff, 201px 1107px #fff, 240px 631px #fff, 1557px 239px #fff,
    1535px 574px #fff, 1551px 965px #fff, 1877px 1387px #fff, 1536px 1082px #fff, 1796px 1012px #fff, 1686px 142px #fff,
    1567px 937px #fff, 280px 1775px #fff, 170px 1838px #fff, 59px 700px #fff, 909px 670px #fff, 1919px 623px #fff,
    564px 1046px #fff, 837px 1430px #fff, 1997px 1769px #fff, 475px 430px #fff, 1563px 311px #fff, 256px 1054px #fff,
    1440px 1644px #fff, 1957px 1917px #fff, 740px 1238px #fff, 378px 1655px #fff, 1112px 1396px #fff, 500px 785px #fff,
    805px 379px #fff, 495px 1105px #fff, 882px 1448px #fff, 332px 1057px #fff, 614px 102px #fff, 192px 436px #fff,
    1215px 1164px #fff, 378px 1388px #fff, 426px 545px #fff, 114px 1488px #fff, 791px 428px #fff, 706px 677px #fff,
    862px 355px #fff, 1045px 1060px #fff, 1364px 964px #fff, 1204px 202px #fff, 1073px 1759px #fff, 346px 1178px #fff,
    972px 525px #fff, 319px 1208px #fff, 1535px 1603px #fff, 1185px 1129px #fff, 1282px 265px #fff, 152px 1010px #fff,
    660px 1871px #fff, 266px 1199px #fff, 367px 1083px #fff, 335px 1976px #fff, 1833px 924px #fff, 1026px 340px #fff,
    220px 28px #fff, 330px 101px #fff, 177px 598px #fff, 1699px 1883px #fff, 1169px 666px #fff, 1471px 92px #fff,
    369px 1220px #fff, 1464px 31px #fff, 944px 1339px #fff, 129px 57px #fff, 711px 1563px #fff, 734px 1295px #fff,
    376px 150px #fff, 97px 1745px #fff, 1940px 939px #fff, 7px 1495px #fff, 310px 267px #fff, 1252px 1719px #fff,
    1478px 1293px #fff, 1894px 286px #fff, 906px 1745px #fff, 474px 1591px #fff, 495px 1876px #fff, 802px 1684px #fff,
    162px 1667px #fff, 344px 551px #fff, 990px 1571px #fff, 1003px 1715px #fff, 1259px 874px #fff, 1115px 571px #fff,
    1597px 999px #fff, 1772px 1280px #fff, 1035px 1529px #fff, 792px 238px #fff, 1701px 769px #fff, 665px 993px #fff,
    457px 772px #fff, 597px 1550px #fff, 408px 59px #fff, 1086px 1672px #fff, 545px 1704px #fff, 956px 1314px #fff,
    1809px 1154px #fff, 1972px 769px #fff, 1841px 501px #fff, 1479px 395px #fff, 1880px 1827px #fff, 808px 1708px #fff,
    1714px 1166px #fff, 1765px 1139px #fff, 533px 115px #fff, 590px 1903px #fff, 384px 1311px #fff, 1389px 1702px #fff,
    1774px 1322px #fff, 1615px 1820px #fff, 44px 420px #fff, 349px 992px #fff, 1913px 1576px #fff, 1121px 1669px #fff,
    1340px 1327px #fff, 1648px 1236px #fff, 240px 1917px #fff, 1260px 1672px #fff, 604px 1192px #fff, 162px 1579px #fff,
    1164px 1652px #fff, 1235px 1509px #fff, 161px 100px #fff, 1645px 1587px #fff, 1757px 57px #fff, 405px 1055px #fff,
    343px 442px #fff, 651px 930px #fff, 1923px 1957px #fff, 1164px 753px #fff, 795px 252px #fff, 1013px 923px #fff,
    1835px 387px #fff, 1574px 1122px #fff, 1465px 1052px #fff, 1658px 1044px #fff, 990px 1708px #fff, 847px 433px #fff,
    1966px 743px #fff, 1331px 817px #fff, 367px 988px #fff, 561px 1560px #fff, 6px 604px #fff, 247px 1051px #fff,
    649px 1782px #fff, 1059px 1545px #fff, 1240px 800px #fff, 1035px 1933px #fff, 1005px 841px #fff, 1135px 1445px #fff,
    1091px 1815px #fff, 423px 1198px #fff, 567px 1768px #fff, 1147px 238px #fff, 156px 126px #fff, 1082px 641px #fff,
    522px 600px #fff, 187px 735px #fff, 1727px 237px #fff, 1546px 31px #fff, 1447px 182px #fff, 1906px 1300px #fff,
    1313px 1220px #fff, 1365px 560px #fff, 1821px 1424px #fff, 678px 459px #fff, 1017px 1948px #fff, 2000px 1178px #fff,
    601px 1368px #fff, 1720px 514px #fff, 156px 579px #fff, 527px 1994px #fff, 1540px 1719px #fff, 287px 1746px #fff,
    1999px 704px #fff, 822px 179px #fff, 171px 1747px #fff, 725px 250px #fff, 149px 1486px #fff, 883px 1769px #fff,
    1959px 1244px #fff;
  box-shadow: 122px 389px #c00, 891px 83px #fff, 1005px 1066px #fff, 1256px 43px #fff, 1165px 1728px #fff,
    1189px 1872px #fff, 1049px 143px #fff, 1534px 1976px #fff, 1608px 1479px #fff, 618px 434px #fff, 315px 423px #fff,
    1141px 83px #fff, 1397px 3px #fff, 1668px 1270px #fff, 1990px 833px #fff, 1430px 577px #fff, 832px 1917px #fff,
    99px 1448px #fff, 32px 1325px #fff, 980px 805px #fff, 20px 828px #fff, 1962px 943px #fff, 1996px 897px #fff,
    1874px 1889px #fff, 1820px 313px #fff, 1255px 1080px #fff, 1064px 72px #fff, 1816px 1671px #fff, 11px 1694px #fff,
    850px 1348px #fff, 219px 906px #fff, 703px 523px #fff, 96px 521px #fff, 470px 1329px #fff, 1300px 123px #fff,
    733px 620px #fff, 590px 46px #fff, 92px 1003px #fff, 948px 1955px #fff, 1410px 541px #fff, 400px 600px #fff,
    1624px 1761px #fff, 1708px 1777px #fff, 640px 1624px #fff, 821px 1559px #fff, 1268px 888px #fff, 1493px 1696px #fff,
    1726px 1658px #fff, 900px 1635px #fff, 720px 1463px #fff, 598px 1350px #fff, 1286px 1705px #fff, 364px 1462px #fff,
    1335px 171px #fff, 1170px 1066px #fff, 590px 1550px #fff, 704px 1539px #fff, 1921px 230px #fff, 1466px 128px #fff,
    842px 812px #fff, 885px 801px #fff, 471px 1220px #fff, 1828px 265px #fff, 1195px 61px #fff, 1563px 81px #fff,
    1110px 1984px #fff, 603px 1370px #fff, 426px 1953px #fff, 578px 1488px #fff, 962px 1362px #fff, 512px 1972px #fff,
    36px 1994px #fff, 425px 1515px #fff, 1716px 1189px #fff, 1401px 1160px #fff, 1706px 268px #fff, 242px 1534px #fff,
    1140px 1920px #fff, 842px 1521px #fff, 283px 1734px #fff, 589px 1251px #fff, 824px 1148px #fff, 1626px 122px #fff,
    1735px 948px #fff, 477px 265px #fff, 1534px 1000px #fff, 1688px 586px #fff, 872px 394px #fff, 682px 42px #fff,
    1734px 623px #fff, 1316px 1041px #fff, 32px 1653px #fff, 1347px 843px #fff, 37px 1831px #fff, 422px 1895px #fff,
    945px 1656px #fff, 1852px 1894px #fff, 1245px 334px #fff, 1829px 1400px #fff, 807px 1090px #fff, 1003px 460px #fff,
    584px 1582px #fff, 669px 546px #fff, 1059px 528px #fff, 89px 143px #fff, 431px 260px #fff, 97px 1926px #fff,
    491px 703px #fff, 820px 1827px #fff, 1712px 897px #fff, 1610px 1439px #fff, 1599px 1849px #fff, 291px 914px #fff,
    1394px 1718px #fff, 444px 56px #fff, 925px 37px #fff, 484px 1491px #fff, 1881px 1694px #fff, 430px 1818px #fff,
    272px 871px #fff, 1982px 484px #fff, 632px 1388px #fff, 1779px 1911px #fff, 1813px 1592px #fff, 644px 1598px #fff,
    27px 1520px #fff, 1066px 1925px #fff, 1264px 1320px #fff, 36px 1449px #fff, 1712px 1815px #fff, 256px 1164px #fff,
    728px 143px #fff, 637px 788px #fff, 703px 1696px #fff, 808px 1506px #fff, 1952px 66px #fff, 1686px 984px #fff,
    630px 389px #fff, 3px 905px #fff, 1563px 110px #fff, 1017px 930px #fff, 1863px 468px #fff, 1475px 436px #fff,
    1127px 1597px #fff, 394px 386px #fff, 1198px 490px #fff, 1222px 1499px #fff, 1522px 266px #fff, 1073px 1781px #fff,
    1293px 1387px #fff, 849px 1341px #fff, 1435px 1600px #fff, 286px 1578px #fff, 569px 1968px #fff, 779px 1127px #fff,
    1669px 1335px #fff, 575px 1263px #fff, 1536px 1166px #fff, 1033px 1487px #fff, 1165px 704px #fff, 834px 1310px #fff,
    741px 1908px #fff, 441px 878px #fff, 1199px 147px #fff, 1492px 104px #fff, 55px 583px #fff, 1830px 1259px #fff,
    1705px 1444px #fff, 790px 1719px #fff, 776px 1357px #fff, 1873px 1460px #fff, 1015px 1649px #fff, 1126px 1789px #fff,
    1986px 1680px #fff, 1946px 677px #fff, 1977px 1351px #fff, 803px 1156px #fff, 668px 1397px #fff, 999px 609px #fff,
    853px 1241px #fff, 224px 329px #fff, 1950px 385px #fff, 1803px 1020px #fff, 277px 1201px #fff, 428px 1782px #fff,
    746px 764px #fff, 406px 764px #fff, 37px 105px #fff, 169px 1102px #fff, 557px 251px #fff, 1517px 490px #fff,
    1468px 788px #fff, 173px 358px #fff, 484px 800px #fff, 1216px 409px #fff, 1635px 1940px #fff, 1514px 1066px #fff,
    371px 1658px #fff, 1520px 82px #fff, 692px 370px #fff, 569px 541px #fff, 1759px 795px #fff, 1919px 699px #fff,
    1271px 1328px #fff, 1682px 857px #fff, 1478px 1217px #fff, 282px 1548px #fff, 163px 1248px #fff, 1478px 272px #fff,
    87px 1878px #fff, 1049px 1048px #fff, 403px 1193px #fff, 589px 1178px #fff, 1887px 1984px #fff, 1763px 734px #fff,
    1028px 1743px #fff, 1663px 1145px #fff, 380px 1867px #fff, 839px 1817px #fff, 1370px 1766px #fff, 495px 627px #fff,
    477px 421px #fff, 1374px 43px #fff, 133px 351px #fff, 1167px 589px #fff, 77px 1894px #fff, 977px 1102px #fff,
    745px 143px #fff, 460px 175px #fff, 775px 1177px #fff, 304px 1302px #fff, 284px 19px #fff, 1546px 1422px #fff,
    1355px 1980px #fff, 387px 1279px #fff, 1077px 1550px #fff, 1696px 185px #fff, 1958px 1393px #fff, 71px 1751px #fff,
    4px 1016px #fff, 997px 1204px #fff, 762px 441px #fff, 1766px 1091px #fff, 200px 1578px #fff, 675px 1455px #fff,
    367px 1899px #fff, 1172px 1398px #fff, 1858px 1149px #fff, 709px 595px #fff, 1304px 1996px #fff, 970px 798px #fff,
    1342px 1587px #fff, 1578px 11px #fff, 701px 1490px #fff, 1795px 302px #fff, 1035px 959px #fff, 443px 32px #fff,
    1027px 308px #fff, 1736px 634px #fff, 1152px 940px #fff, 18px 1957px #fff, 1289px 1727px #fff, 1235px 1799px #fff,
    848px 657px #fff, 1718px 1909px #fff, 1787px 1689px #fff, 1533px 828px #fff, 429px 1282px #fff, 1675px 1543px #fff,
    1061px 1186px #fff, 1412px 604px #fff, 1644px 1967px #fff, 1124px 942px #fff, 736px 764px #fff, 78px 263px #fff,
    1352px 1462px #fff, 1465px 676px #fff, 49px 602px #fff, 178px 1997px #fff, 869px 1501px #fff, 1245px 1831px #fff,
    574px 44px #fff, 789px 1130px #fff, 1260px 1459px #fff, 1531px 1984px #fff, 781px 1294px #fff, 748px 854px #fff,
    132px 1807px #fff, 1019px 752px #fff, 864px 1281px #fff, 1054px 936px #fff, 1866px 715px #fff, 1053px 644px #fff,
    721px 94px #fff, 875px 1059px #fff, 1370px 333px #fff, 523px 823px #fff, 1799px 1762px #fff, 1145px 795px #fff,
    480px 1192px #fff, 1346px 1196px #fff, 384px 534px #fff, 391px 920px #fff, 1360px 1005px #fff, 1556px 1327px #fff,
    1024px 405px #fff, 1802px 1330px #fff, 66px 633px #fff, 77px 1091px #fff, 1001px 214px #fff, 541px 57px #fff,
    132px 1186px #fff, 840px 319px #fff, 978px 606px #fff, 599px 1546px #fff, 774px 1432px #fff, 1534px 1774px #fff,
    322px 1940px #fff, 1557px 620px #fff, 1409px 731px #fff, 7px 581px #fff, 1659px 367px #fff, 684px 211px #fff,
    368px 1512px #fff, 356px 836px #fff, 911px 492px #fff, 1936px 1541px #fff, 1762px 827px #fff, 941px 269px #fff,
    391px 570px #fff, 1387px 1110px #fff, 1444px 1181px #fff, 771px 1311px #fff, 1255px 1313px #fff, 1019px 118px #fff,
    304px 610px #fff, 746px 1684px #fff, 1933px 1264px #fff, 939px 966px #fff, 538px 1913px #fff, 1165px 887px #fff,
    1873px 876px #fff, 781px 1325px #fff, 793px 937px #fff, 1723px 291px #fff, 588px 926px #fff, 120px 594px #fff,
    479px 1123px #fff, 867px 124px #fff, 1220px 1756px #fff, 819px 587px #fff, 159px 1584px #fff, 268px 407px #fff,
    1909px 1802px #fff, 790px 1298px #fff, 1407px 1766px #fff, 1635px 77px #fff, 1801px 252px #fff, 1397px 1715px #fff,
    1100px 792px #fff, 632px 1247px #fff, 476px 1160px #fff, 198px 1316px #fff, 666px 590px #fff, 171px 1796px #fff,
    968px 649px #fff, 641px 809px #fff, 1925px 1518px #fff, 1306px 1646px #fff, 612px 1823px #fff, 928px 598px #fff,
    28px 311px #fff, 808px 1292px #fff, 1358px 85px #fff, 1651px 809px #fff, 1053px 884px #fff, 1289px 1392px #fff,
    745px 333px #fff, 822px 155px #fff, 1595px 382px #fff, 229px 308px #fff, 761px 46px #fff, 1820px 387px #fff,
    1330px 1635px #fff, 605px 1754px #fff, 1104px 1170px #fff, 1600px 1314px #fff, 1766px 1924px #fff, 859px 2000px #fff,
    47px 502px #fff, 1178px 218px #fff, 386px 664px #fff, 254px 875px #fff, 322px 641px #fff, 1608px 1578px #fff,
    631px 416px #fff, 38px 1485px #fff, 999px 1390px #fff, 1957px 378px #fff, 1983px 885px #fff, 1098px 222px #fff,
    1367px 124px #fff, 1370px 773px #fff, 169px 1131px #fff, 983px 973px #fff, 258px 547px #fff, 1540px 35px #fff,
    1582px 1301px #fff, 93px 1625px #fff, 567px 503px #fff, 1523px 1102px #fff, 1992px 394px #fff, 579px 669px #fff,
    1466px 537px #fff, 1202px 1353px #fff, 67px 1018px #fff, 201px 1107px #fff, 240px 631px #fff, 1557px 239px #fff,
    1535px 574px #fff, 1551px 965px #fff, 1877px 1387px #fff, 1536px 1082px #fff, 1796px 1012px #fff, 1686px 142px #fff,
    1567px 937px #fff, 280px 1775px #fff, 170px 1838px #fff, 59px 700px #fff, 909px 670px #fff, 1919px 623px #fff,
    564px 1046px #fff, 837px 1430px #fff, 1997px 1769px #fff, 475px 430px #fff, 1563px 311px #fff, 256px 1054px #fff,
    1440px 1644px #fff, 1957px 1917px #fff, 740px 1238px #fff, 378px 1655px #fff, 1112px 1396px #fff, 500px 785px #fff,
    805px 379px #fff, 495px 1105px #fff, 882px 1448px #fff, 332px 1057px #fff, 614px 102px #fff, 192px 436px #fff,
    1215px 1164px #fff, 378px 1388px #fff, 426px 545px #fff, 114px 1488px #fff, 791px 428px #fff, 706px 677px #fff,
    862px 355px #fff, 1045px 1060px #fff, 1364px 964px #fff, 1204px 202px #fff, 1073px 1759px #fff, 346px 1178px #fff,
    972px 525px #fff, 319px 1208px #fff, 1535px 1603px #fff, 1185px 1129px #fff, 1282px 265px #fff, 152px 1010px #fff,
    660px 1871px #fff, 266px 1199px #fff, 367px 1083px #fff, 335px 1976px #fff, 1833px 924px #fff, 1026px 340px #fff,
    220px 28px #fff, 330px 101px #fff, 177px 598px #fff, 1699px 1883px #fff, 1169px 666px #fff, 1471px 92px #fff,
    369px 1220px #fff, 1464px 31px #fff, 944px 1339px #fff, 129px 57px #fff, 711px 1563px #fff, 734px 1295px #fff,
    376px 150px #fff, 97px 1745px #fff, 1940px 939px #fff, 7px 1495px #fff, 310px 267px #fff, 1252px 1719px #fff,
    1478px 1293px #fff, 1894px 286px #fff, 906px 1745px #fff, 474px 1591px #fff, 495px 1876px #fff, 802px 1684px #fff,
    162px 1667px #fff, 344px 551px #fff, 990px 1571px #fff, 1003px 1715px #fff, 1259px 874px #fff, 1115px 571px #fff,
    1597px 999px #fff, 1772px 1280px #fff, 1035px 1529px #fff, 792px 238px #fff, 1701px 769px #fff, 665px 993px #fff,
    457px 772px #fff, 597px 1550px #fff, 408px 59px #fff, 1086px 1672px #fff, 545px 1704px #fff, 956px 1314px #fff,
    1809px 1154px #fff, 1972px 769px #fff, 1841px 501px #fff, 1479px 395px #fff, 1880px 1827px #fff, 808px 1708px #fff,
    1714px 1166px #fff, 1765px 1139px #fff, 533px 115px #fff, 590px 1903px #fff, 384px 1311px #fff, 1389px 1702px #fff,
    1774px 1322px #fff, 1615px 1820px #fff, 44px 420px #fff, 349px 992px #fff, 1913px 1576px #fff, 1121px 1669px #fff,
    1340px 1327px #fff, 1648px 1236px #fff, 240px 1917px #fff, 1260px 1672px #fff, 604px 1192px #fff, 162px 1579px #fff,
    1164px 1652px #fff, 1235px 1509px #fff, 161px 100px #fff, 1645px 1587px #fff, 1757px 57px #fff, 405px 1055px #fff,
    343px 442px #fff, 651px 930px #fff, 1923px 1957px #fff, 1164px 753px #fff, 795px 252px #fff, 1013px 923px #fff,
    1835px 387px #fff, 1574px 1122px #fff, 1465px 1052px #fff, 1658px 1044px #fff, 990px 1708px #fff, 847px 433px #fff,
    1966px 743px #fff, 1331px 817px #fff, 367px 988px #fff, 561px 1560px #fff, 6px 604px #fff, 247px 1051px #fff,
    649px 1782px #fff, 1059px 1545px #fff, 1240px 800px #fff, 1035px 1933px #fff, 1005px 841px #fff, 1135px 1445px #fff,
    1091px 1815px #fff, 423px 1198px #fff, 567px 1768px #fff, 1147px 238px #fff, 156px 126px #fff, 1082px 641px #fff,
    522px 600px #fff, 187px 735px #fff, 1727px 237px #fff, 1546px 31px #fff, 1447px 182px #fff, 1906px 1300px #fff,
    1313px 1220px #fff, 1365px 560px #fff, 1821px 1424px #fff, 678px 459px #fff, 1017px 1948px #fff, 2000px 1178px #fff,
    601px 1368px #fff, 1720px 514px #fff, 156px 579px #fff, 527px 1994px #fff, 1540px 1719px #fff, 287px 1746px #fff,
    1999px 704px #fff, 822px 179px #fff, 171px 1747px #fff, 725px 250px #fff, 149px 1486px #fff, 883px 1769px #fff,
    1959px 1244px #fff;
  -webkit-animation: animStar 80s linear infinite;
  animation: animStar 80s linear infinite;
}
#star::after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  border-radius: 50%;
  -webkit-box-shadow: 122px 389px #c00, 891px 83px #fff, 1005px 1066px #fff, 1256px 43px #fff, 1165px 1728px #fff,
    1189px 1872px #fff, 1049px 143px #fff, 1534px 1976px #fff, 1608px 1479px #fff, 618px 434px #fff, 315px 423px #fff,
    1141px 83px #fff, 1397px 3px #fff, 1668px 1270px #fff, 1990px 833px #fff, 1430px 577px #fff, 832px 1917px #fff,
    99px 1448px #fff, 32px 1325px #fff, 980px 805px #fff, 20px 828px #fff, 1962px 943px #fff, 1996px 897px #fff,
    1874px 1889px #fff, 1820px 313px #fff, 1255px 1080px #fff, 1064px 72px #fff, 1816px 1671px #fff, 11px 1694px #fff,
    850px 1348px #fff, 219px 906px #fff, 703px 523px #fff, 96px 521px #fff, 470px 1329px #fff, 1300px 123px #fff,
    733px 620px #fff, 590px 46px #fff, 92px 1003px #fff, 948px 1955px #fff, 1410px 541px #fff, 400px 600px #fff,
    1624px 1761px #fff, 1708px 1777px #fff, 640px 1624px #fff, 821px 1559px #fff, 1268px 888px #fff, 1493px 1696px #fff,
    1726px 1658px #fff, 900px 1635px #fff, 720px 1463px #fff, 598px 1350px #fff, 1286px 1705px #fff, 364px 1462px #fff,
    1335px 171px #fff, 1170px 1066px #fff, 590px 1550px #fff, 704px 1539px #fff, 1921px 230px #fff, 1466px 128px #fff,
    842px 812px #fff, 885px 801px #fff, 471px 1220px #fff, 1828px 265px #fff, 1195px 61px #fff, 1563px 81px #fff,
    1110px 1984px #fff, 603px 1370px #fff, 426px 1953px #fff, 578px 1488px #fff, 962px 1362px #fff, 512px 1972px #fff,
    36px 1994px #fff, 425px 1515px #fff, 1716px 1189px #fff, 1401px 1160px #fff, 1706px 268px #fff, 242px 1534px #fff,
    1140px 1920px #fff, 842px 1521px #fff, 283px 1734px #fff, 589px 1251px #fff, 824px 1148px #fff, 1626px 122px #fff,
    1735px 948px #fff, 477px 265px #fff, 1534px 1000px #fff, 1688px 586px #fff, 872px 394px #fff, 682px 42px #fff,
    1734px 623px #fff, 1316px 1041px #fff, 32px 1653px #fff, 1347px 843px #fff, 37px 1831px #fff, 422px 1895px #fff,
    945px 1656px #fff, 1852px 1894px #fff, 1245px 334px #fff, 1829px 1400px #fff, 807px 1090px #fff, 1003px 460px #fff,
    584px 1582px #fff, 669px 546px #fff, 1059px 528px #fff, 89px 143px #fff, 431px 260px #fff, 97px 1926px #fff,
    491px 703px #fff, 820px 1827px #fff, 1712px 897px #fff, 1610px 1439px #fff, 1599px 1849px #fff, 291px 914px #fff,
    1394px 1718px #fff, 444px 56px #fff, 925px 37px #fff, 484px 1491px #fff, 1881px 1694px #fff, 430px 1818px #fff,
    272px 871px #fff, 1982px 484px #fff, 632px 1388px #fff, 1779px 1911px #fff, 1813px 1592px #fff, 644px 1598px #fff,
    27px 1520px #fff, 1066px 1925px #fff, 1264px 1320px #fff, 36px 1449px #fff, 1712px 1815px #fff, 256px 1164px #fff,
    728px 143px #fff, 637px 788px #fff, 703px 1696px #fff, 808px 1506px #fff, 1952px 66px #fff, 1686px 984px #fff,
    630px 389px #fff, 3px 905px #fff, 1563px 110px #fff, 1017px 930px #fff, 1863px 468px #fff, 1475px 436px #fff,
    1127px 1597px #fff, 394px 386px #fff, 1198px 490px #fff, 1222px 1499px #fff, 1522px 266px #fff, 1073px 1781px #fff,
    1293px 1387px #fff, 849px 1341px #fff, 1435px 1600px #fff, 286px 1578px #fff, 569px 1968px #fff, 779px 1127px #fff,
    1669px 1335px #fff, 575px 1263px #fff, 1536px 1166px #fff, 1033px 1487px #fff, 1165px 704px #fff, 834px 1310px #fff,
    741px 1908px #fff, 441px 878px #fff, 1199px 147px #fff, 1492px 104px #fff, 55px 583px #fff, 1830px 1259px #fff,
    1705px 1444px #fff, 790px 1719px #fff, 776px 1357px #fff, 1873px 1460px #fff, 1015px 1649px #fff, 1126px 1789px #fff,
    1986px 1680px #fff, 1946px 677px #fff, 1977px 1351px #fff, 803px 1156px #fff, 668px 1397px #fff, 999px 609px #fff,
    853px 1241px #fff, 224px 329px #fff, 1950px 385px #fff, 1803px 1020px #fff, 277px 1201px #fff, 428px 1782px #fff,
    746px 764px #fff, 406px 764px #fff, 37px 105px #fff, 169px 1102px #fff, 557px 251px #fff, 1517px 490px #fff,
    1468px 788px #fff, 173px 358px #fff, 484px 800px #fff, 1216px 409px #fff, 1635px 1940px #fff, 1514px 1066px #fff,
    371px 1658px #fff, 1520px 82px #fff, 692px 370px #fff, 569px 541px #fff, 1759px 795px #fff, 1919px 699px #fff,
    1271px 1328px #fff, 1682px 857px #fff, 1478px 1217px #fff, 282px 1548px #fff, 163px 1248px #fff, 1478px 272px #fff,
    87px 1878px #fff, 1049px 1048px #fff, 403px 1193px #fff, 589px 1178px #fff, 1887px 1984px #fff, 1763px 734px #fff,
    1028px 1743px #fff, 1663px 1145px #fff, 380px 1867px #fff, 839px 1817px #fff, 1370px 1766px #fff, 495px 627px #fff,
    477px 421px #fff, 1374px 43px #fff, 133px 351px #fff, 1167px 589px #fff, 77px 1894px #fff, 977px 1102px #fff,
    745px 143px #fff, 460px 175px #fff, 775px 1177px #fff, 304px 1302px #fff, 284px 19px #fff, 1546px 1422px #fff,
    1355px 1980px #fff, 387px 1279px #fff, 1077px 1550px #fff, 1696px 185px #fff, 1958px 1393px #fff, 71px 1751px #fff,
    4px 1016px #fff, 997px 1204px #fff, 762px 441px #fff, 1766px 1091px #fff, 200px 1578px #fff, 675px 1455px #fff,
    367px 1899px #fff, 1172px 1398px #fff, 1858px 1149px #fff, 709px 595px #fff, 1304px 1996px #fff, 970px 798px #fff,
    1342px 1587px #fff, 1578px 11px #fff, 701px 1490px #fff, 1795px 302px #fff, 1035px 959px #fff, 443px 32px #fff,
    1027px 308px #fff, 1736px 634px #fff, 1152px 940px #fff, 18px 1957px #fff, 1289px 1727px #fff, 1235px 1799px #fff,
    848px 657px #fff, 1718px 1909px #fff, 1787px 1689px #fff, 1533px 828px #fff, 429px 1282px #fff, 1675px 1543px #fff,
    1061px 1186px #fff, 1412px 604px #fff, 1644px 1967px #fff, 1124px 942px #fff, 736px 764px #fff, 78px 263px #fff,
    1352px 1462px #fff, 1465px 676px #fff, 49px 602px #fff, 178px 1997px #fff, 869px 1501px #fff, 1245px 1831px #fff,
    574px 44px #fff, 789px 1130px #fff, 1260px 1459px #fff, 1531px 1984px #fff, 781px 1294px #fff, 748px 854px #fff,
    132px 1807px #fff, 1019px 752px #fff, 864px 1281px #fff, 1054px 936px #fff, 1866px 715px #fff, 1053px 644px #fff,
    721px 94px #fff, 875px 1059px #fff, 1370px 333px #fff, 523px 823px #fff, 1799px 1762px #fff, 1145px 795px #fff,
    480px 1192px #fff, 1346px 1196px #fff, 384px 534px #fff, 391px 920px #fff, 1360px 1005px #fff, 1556px 1327px #fff,
    1024px 405px #fff, 1802px 1330px #fff, 66px 633px #fff, 77px 1091px #fff, 1001px 214px #fff, 541px 57px #fff,
    132px 1186px #fff, 840px 319px #fff, 978px 606px #fff, 599px 1546px #fff, 774px 1432px #fff, 1534px 1774px #fff,
    322px 1940px #fff, 1557px 620px #fff, 1409px 731px #fff, 7px 581px #fff, 1659px 367px #fff, 684px 211px #fff,
    368px 1512px #fff, 356px 836px #fff, 911px 492px #fff, 1936px 1541px #fff, 1762px 827px #fff, 941px 269px #fff,
    391px 570px #fff, 1387px 1110px #fff, 1444px 1181px #fff, 771px 1311px #fff, 1255px 1313px #fff, 1019px 118px #fff,
    304px 610px #fff, 746px 1684px #fff, 1933px 1264px #fff, 939px 966px #fff, 538px 1913px #fff, 1165px 887px #fff,
    1873px 876px #fff, 781px 1325px #fff, 793px 937px #fff, 1723px 291px #fff, 588px 926px #fff, 120px 594px #fff,
    479px 1123px #fff, 867px 124px #fff, 1220px 1756px #fff, 819px 587px #fff, 159px 1584px #fff, 268px 407px #fff,
    1909px 1802px #fff, 790px 1298px #fff, 1407px 1766px #fff, 1635px 77px #fff, 1801px 252px #fff, 1397px 1715px #fff,
    1100px 792px #fff, 632px 1247px #fff, 476px 1160px #fff, 198px 1316px #fff, 666px 590px #fff, 171px 1796px #fff,
    968px 649px #fff, 641px 809px #fff, 1925px 1518px #fff, 1306px 1646px #fff, 612px 1823px #fff, 928px 598px #fff,
    28px 311px #fff, 808px 1292px #fff, 1358px 85px #fff, 1651px 809px #fff, 1053px 884px #fff, 1289px 1392px #fff,
    745px 333px #fff, 822px 155px #fff, 1595px 382px #fff, 229px 308px #fff, 761px 46px #fff, 1820px 387px #fff,
    1330px 1635px #fff, 605px 1754px #fff, 1104px 1170px #fff, 1600px 1314px #fff, 1766px 1924px #fff, 859px 2000px #fff,
    47px 502px #fff, 1178px 218px #fff, 386px 664px #fff, 254px 875px #fff, 322px 641px #fff, 1608px 1578px #fff,
    631px 416px #fff, 38px 1485px #fff, 999px 1390px #fff, 1957px 378px #fff, 1983px 885px #fff, 1098px 222px #fff,
    1367px 124px #fff, 1370px 773px #fff, 169px 1131px #fff, 983px 973px #fff, 258px 547px #fff, 1540px 35px #fff,
    1582px 1301px #fff, 93px 1625px #fff, 567px 503px #fff, 1523px 1102px #fff, 1992px 394px #fff, 579px 669px #fff,
    1466px 537px #fff, 1202px 1353px #fff, 67px 1018px #fff, 201px 1107px #fff, 240px 631px #fff, 1557px 239px #fff,
    1535px 574px #fff, 1551px 965px #fff, 1877px 1387px #fff, 1536px 1082px #fff, 1796px 1012px #fff, 1686px 142px #fff,
    1567px 937px #fff, 280px 1775px #fff, 170px 1838px #fff, 59px 700px #fff, 909px 670px #fff, 1919px 623px #fff,
    564px 1046px #fff, 837px 1430px #fff, 1997px 1769px #fff, 475px 430px #fff, 1563px 311px #fff, 256px 1054px #fff,
    1440px 1644px #fff, 1957px 1917px #fff, 740px 1238px #fff, 378px 1655px #fff, 1112px 1396px #fff, 500px 785px #fff,
    805px 379px #fff, 495px 1105px #fff, 882px 1448px #fff, 332px 1057px #fff, 614px 102px #fff, 192px 436px #fff,
    1215px 1164px #fff, 378px 1388px #fff, 426px 545px #fff, 114px 1488px #fff, 791px 428px #fff, 706px 677px #fff,
    862px 355px #fff, 1045px 1060px #fff, 1364px 964px #fff, 1204px 202px #fff, 1073px 1759px #fff, 346px 1178px #fff,
    972px 525px #fff, 319px 1208px #fff, 1535px 1603px #fff, 1185px 1129px #fff, 1282px 265px #fff, 152px 1010px #fff,
    660px 1871px #fff, 266px 1199px #fff, 367px 1083px #fff, 335px 1976px #fff, 1833px 924px #fff, 1026px 340px #fff,
    220px 28px #fff, 330px 101px #fff, 177px 598px #fff, 1699px 1883px #fff, 1169px 666px #fff, 1471px 92px #fff,
    369px 1220px #fff, 1464px 31px #fff, 944px 1339px #fff, 129px 57px #fff, 711px 1563px #fff, 734px 1295px #fff,
    376px 150px #fff, 97px 1745px #fff, 1940px 939px #fff, 7px 1495px #fff, 310px 267px #fff, 1252px 1719px #fff,
    1478px 1293px #fff, 1894px 286px #fff, 906px 1745px #fff, 474px 1591px #fff, 495px 1876px #fff, 802px 1684px #fff,
    162px 1667px #fff, 344px 551px #fff, 990px 1571px #fff, 1003px 1715px #fff, 1259px 874px #fff, 1115px 571px #fff,
    1597px 999px #fff, 1772px 1280px #fff, 1035px 1529px #fff, 792px 238px #fff, 1701px 769px #fff, 665px 993px #fff,
    457px 772px #fff, 597px 1550px #fff, 408px 59px #fff, 1086px 1672px #fff, 545px 1704px #fff, 956px 1314px #fff,
    1809px 1154px #fff, 1972px 769px #fff, 1841px 501px #fff, 1479px 395px #fff, 1880px 1827px #fff, 808px 1708px #fff,
    1714px 1166px #fff, 1765px 1139px #fff, 533px 115px #fff, 590px 1903px #fff, 384px 1311px #fff, 1389px 1702px #fff,
    1774px 1322px #fff, 1615px 1820px #fff, 44px 420px #fff, 349px 992px #fff, 1913px 1576px #fff, 1121px 1669px #fff,
    1340px 1327px #fff, 1648px 1236px #fff, 240px 1917px #fff, 1260px 1672px #fff, 604px 1192px #fff, 162px 1579px #fff,
    1164px 1652px #fff, 1235px 1509px #fff, 161px 100px #fff, 1645px 1587px #fff, 1757px 57px #fff, 405px 1055px #fff,
    343px 442px #fff, 651px 930px #fff, 1923px 1957px #fff, 1164px 753px #fff, 795px 252px #fff, 1013px 923px #fff,
    1835px 387px #fff, 1574px 1122px #fff, 1465px 1052px #fff, 1658px 1044px #fff, 990px 1708px #fff, 847px 433px #fff,
    1966px 743px #fff, 1331px 817px #fff, 367px 988px #fff, 561px 1560px #fff, 6px 604px #fff, 247px 1051px #fff,
    649px 1782px #fff, 1059px 1545px #fff, 1240px 800px #fff, 1035px 1933px #fff, 1005px 841px #fff, 1135px 1445px #fff,
    1091px 1815px #fff, 423px 1198px #fff, 567px 1768px #fff, 1147px 238px #fff, 156px 126px #fff, 1082px 641px #fff,
    522px 600px #fff, 187px 735px #fff, 1727px 237px #fff, 1546px 31px #fff, 1447px 182px #fff, 1906px 1300px #fff,
    1313px 1220px #fff, 1365px 560px #fff, 1821px 1424px #fff, 678px 459px #fff, 1017px 1948px #fff, 2000px 1178px #fff,
    601px 1368px #fff, 1720px 514px #fff, 156px 579px #fff, 527px 1994px #fff, 1540px 1719px #fff, 287px 1746px #fff,
    1999px 704px #fff, 822px 179px #fff, 171px 1747px #fff, 725px 250px #fff, 149px 1486px #fff, 883px 1769px #fff,
    1959px 1244px #fff;
  box-shadow: 122px 389px #c00, 891px 83px #fff, 1005px 1066px #fff, 1256px 43px #fff, 1165px 1728px #fff,
    1189px 1872px #fff, 1049px 143px #fff, 1534px 1976px #fff, 1608px 1479px #fff, 618px 434px #fff, 315px 423px #fff,
    1141px 83px #fff, 1397px 3px #fff, 1668px 1270px #fff, 1990px 833px #fff, 1430px 577px #fff, 832px 1917px #fff,
    99px 1448px #fff, 32px 1325px #fff, 980px 805px #fff, 20px 828px #fff, 1962px 943px #fff, 1996px 897px #fff,
    1874px 1889px #fff, 1820px 313px #fff, 1255px 1080px #fff, 1064px 72px #fff, 1816px 1671px #fff, 11px 1694px #fff,
    850px 1348px #fff, 219px 906px #fff, 703px 523px #fff, 96px 521px #fff, 470px 1329px #fff, 1300px 123px #fff,
    733px 620px #fff, 590px 46px #fff, 92px 1003px #fff, 948px 1955px #fff, 1410px 541px #fff, 400px 600px #fff,
    1624px 1761px #fff, 1708px 1777px #fff, 640px 1624px #fff, 821px 1559px #fff, 1268px 888px #fff, 1493px 1696px #fff,
    1726px 1658px #fff, 900px 1635px #fff, 720px 1463px #fff, 598px 1350px #fff, 1286px 1705px #fff, 364px 1462px #fff,
    1335px 171px #fff, 1170px 1066px #fff, 590px 1550px #fff, 704px 1539px #fff, 1921px 230px #fff, 1466px 128px #fff,
    842px 812px #fff, 885px 801px #fff, 471px 1220px #fff, 1828px 265px #fff, 1195px 61px #fff, 1563px 81px #fff,
    1110px 1984px #fff, 603px 1370px #fff, 426px 1953px #fff, 578px 1488px #fff, 962px 1362px #fff, 512px 1972px #fff,
    36px 1994px #fff, 425px 1515px #fff, 1716px 1189px #fff, 1401px 1160px #fff, 1706px 268px #fff, 242px 1534px #fff,
    1140px 1920px #fff, 842px 1521px #fff, 283px 1734px #fff, 589px 1251px #fff, 824px 1148px #fff, 1626px 122px #fff,
    1735px 948px #fff, 477px 265px #fff, 1534px 1000px #fff, 1688px 586px #fff, 872px 394px #fff, 682px 42px #fff,
    1734px 623px #fff, 1316px 1041px #fff, 32px 1653px #fff, 1347px 843px #fff, 37px 1831px #fff, 422px 1895px #fff,
    945px 1656px #fff, 1852px 1894px #fff, 1245px 334px #fff, 1829px 1400px #fff, 807px 1090px #fff, 1003px 460px #fff,
    584px 1582px #fff, 669px 546px #fff, 1059px 528px #fff, 89px 143px #fff, 431px 260px #fff, 97px 1926px #fff,
    491px 703px #fff, 820px 1827px #fff, 1712px 897px #fff, 1610px 1439px #fff, 1599px 1849px #fff, 291px 914px #fff,
    1394px 1718px #fff, 444px 56px #fff, 925px 37px #fff, 484px 1491px #fff, 1881px 1694px #fff, 430px 1818px #fff,
    272px 871px #fff, 1982px 484px #fff, 632px 1388px #fff, 1779px 1911px #fff, 1813px 1592px #fff, 644px 1598px #fff,
    27px 1520px #fff, 1066px 1925px #fff, 1264px 1320px #fff, 36px 1449px #fff, 1712px 1815px #fff, 256px 1164px #fff,
    728px 143px #fff, 637px 788px #fff, 703px 1696px #fff, 808px 1506px #fff, 1952px 66px #fff, 1686px 984px #fff,
    630px 389px #fff, 3px 905px #fff, 1563px 110px #fff, 1017px 930px #fff, 1863px 468px #fff, 1475px 436px #fff,
    1127px 1597px #fff, 394px 386px #fff, 1198px 490px #fff, 1222px 1499px #fff, 1522px 266px #fff, 1073px 1781px #fff,
    1293px 1387px #fff, 849px 1341px #fff, 1435px 1600px #fff, 286px 1578px #fff, 569px 1968px #fff, 779px 1127px #fff,
    1669px 1335px #fff, 575px 1263px #fff, 1536px 1166px #fff, 1033px 1487px #fff, 1165px 704px #fff, 834px 1310px #fff,
    741px 1908px #fff, 441px 878px #fff, 1199px 147px #fff, 1492px 104px #fff, 55px 583px #fff, 1830px 1259px #fff,
    1705px 1444px #fff, 790px 1719px #fff, 776px 1357px #fff, 1873px 1460px #fff, 1015px 1649px #fff, 1126px 1789px #fff,
    1986px 1680px #fff, 1946px 677px #fff, 1977px 1351px #fff, 803px 1156px #fff, 668px 1397px #fff, 999px 609px #fff,
    853px 1241px #fff, 224px 329px #fff, 1950px 385px #fff, 1803px 1020px #fff, 277px 1201px #fff, 428px 1782px #fff,
    746px 764px #fff, 406px 764px #fff, 37px 105px #fff, 169px 1102px #fff, 557px 251px #fff, 1517px 490px #fff,
    1468px 788px #fff, 173px 358px #fff, 484px 800px #fff, 1216px 409px #fff, 1635px 1940px #fff, 1514px 1066px #fff,
    371px 1658px #fff, 1520px 82px #fff, 692px 370px #fff, 569px 541px #fff, 1759px 795px #fff, 1919px 699px #fff,
    1271px 1328px #fff, 1682px 857px #fff, 1478px 1217px #fff, 282px 1548px #fff, 163px 1248px #fff, 1478px 272px #fff,
    87px 1878px #fff, 1049px 1048px #fff, 403px 1193px #fff, 589px 1178px #fff, 1887px 1984px #fff, 1763px 734px #fff,
    1028px 1743px #fff, 1663px 1145px #fff, 380px 1867px #fff, 839px 1817px #fff, 1370px 1766px #fff, 495px 627px #fff,
    477px 421px #fff, 1374px 43px #fff, 133px 351px #fff, 1167px 589px #fff, 77px 1894px #fff, 977px 1102px #fff,
    745px 143px #fff, 460px 175px #fff, 775px 1177px #fff, 304px 1302px #fff, 284px 19px #fff, 1546px 1422px #fff,
    1355px 1980px #fff, 387px 1279px #fff, 1077px 1550px #fff, 1696px 185px #fff, 1958px 1393px #fff, 71px 1751px #fff,
    4px 1016px #fff, 997px 1204px #fff, 762px 441px #fff, 1766px 1091px #fff, 200px 1578px #fff, 675px 1455px #fff,
    367px 1899px #fff, 1172px 1398px #fff, 1858px 1149px #fff, 709px 595px #fff, 1304px 1996px #fff, 970px 798px #fff,
    1342px 1587px #fff, 1578px 11px #fff, 701px 1490px #fff, 1795px 302px #fff, 1035px 959px #fff, 443px 32px #fff,
    1027px 308px #fff, 1736px 634px #fff, 1152px 940px #fff, 18px 1957px #fff, 1289px 1727px #fff, 1235px 1799px #fff,
    848px 657px #fff, 1718px 1909px #fff, 1787px 1689px #fff, 1533px 828px #fff, 429px 1282px #fff, 1675px 1543px #fff,
    1061px 1186px #fff, 1412px 604px #fff, 1644px 1967px #fff, 1124px 942px #fff, 736px 764px #fff, 78px 263px #fff,
    1352px 1462px #fff, 1465px 676px #fff, 49px 602px #fff, 178px 1997px #fff, 869px 1501px #fff, 1245px 1831px #fff,
    574px 44px #fff, 789px 1130px #fff, 1260px 1459px #fff, 1531px 1984px #fff, 781px 1294px #fff, 748px 854px #fff,
    132px 1807px #fff, 1019px 752px #fff, 864px 1281px #fff, 1054px 936px #fff, 1866px 715px #fff, 1053px 644px #fff,
    721px 94px #fff, 875px 1059px #fff, 1370px 333px #fff, 523px 823px #fff, 1799px 1762px #fff, 1145px 795px #fff,
    480px 1192px #fff, 1346px 1196px #fff, 384px 534px #fff, 391px 920px #fff, 1360px 1005px #fff, 1556px 1327px #fff,
    1024px 405px #fff, 1802px 1330px #fff, 66px 633px #fff, 77px 1091px #fff, 1001px 214px #fff, 541px 57px #fff,
    132px 1186px #fff, 840px 319px #fff, 978px 606px #fff, 599px 1546px #fff, 774px 1432px #fff, 1534px 1774px #fff,
    322px 1940px #fff, 1557px 620px #fff, 1409px 731px #fff, 7px 581px #fff, 1659px 367px #fff, 684px 211px #fff,
    368px 1512px #fff, 356px 836px #fff, 911px 492px #fff, 1936px 1541px #fff, 1762px 827px #fff, 941px 269px #fff,
    391px 570px #fff, 1387px 1110px #fff, 1444px 1181px #fff, 771px 1311px #fff, 1255px 1313px #fff, 1019px 118px #fff,
    304px 610px #fff, 746px 1684px #fff, 1933px 1264px #fff, 939px 966px #fff, 538px 1913px #fff, 1165px 887px #fff,
    1873px 876px #fff, 781px 1325px #fff, 793px 937px #fff, 1723px 291px #fff, 588px 926px #fff, 120px 594px #fff,
    479px 1123px #fff, 867px 124px #fff, 1220px 1756px #fff, 819px 587px #fff, 159px 1584px #fff, 268px 407px #fff,
    1909px 1802px #fff, 790px 1298px #fff, 1407px 1766px #fff, 1635px 77px #fff, 1801px 252px #fff, 1397px 1715px #fff,
    1100px 792px #fff, 632px 1247px #fff, 476px 1160px #fff, 198px 1316px #fff, 666px 590px #fff, 171px 1796px #fff,
    968px 649px #fff, 641px 809px #fff, 1925px 1518px #fff, 1306px 1646px #fff, 612px 1823px #fff, 928px 598px #fff,
    28px 311px #fff, 808px 1292px #fff, 1358px 85px #fff, 1651px 809px #fff, 1053px 884px #fff, 1289px 1392px #fff,
    745px 333px #fff, 822px 155px #fff, 1595px 382px #fff, 229px 308px #fff, 761px 46px #fff, 1820px 387px #fff,
    1330px 1635px #fff, 605px 1754px #fff, 1104px 1170px #fff, 1600px 1314px #fff, 1766px 1924px #fff, 859px 2000px #fff,
    47px 502px #fff, 1178px 218px #fff, 386px 664px #fff, 254px 875px #fff, 322px 641px #fff, 1608px 1578px #fff,
    631px 416px #fff, 38px 1485px #fff, 999px 1390px #fff, 1957px 378px #fff, 1983px 885px #fff, 1098px 222px #fff,
    1367px 124px #fff, 1370px 773px #fff, 169px 1131px #fff, 983px 973px #fff, 258px 547px #fff, 1540px 35px #fff,
    1582px 1301px #fff, 93px 1625px #fff, 567px 503px #fff, 1523px 1102px #fff, 1992px 394px #fff, 579px 669px #fff,
    1466px 537px #fff, 1202px 1353px #fff, 67px 1018px #fff, 201px 1107px #fff, 240px 631px #fff, 1557px 239px #fff,
    1535px 574px #fff, 1551px 965px #fff, 1877px 1387px #fff, 1536px 1082px #fff, 1796px 1012px #fff, 1686px 142px #fff,
    1567px 937px #fff, 280px 1775px #fff, 170px 1838px #fff, 59px 700px #fff, 909px 670px #fff, 1919px 623px #fff,
    564px 1046px #fff, 837px 1430px #fff, 1997px 1769px #fff, 475px 430px #fff, 1563px 311px #fff, 256px 1054px #fff,
    1440px 1644px #fff, 1957px 1917px #fff, 740px 1238px #fff, 378px 1655px #fff, 1112px 1396px #fff, 500px 785px #fff,
    805px 379px #fff, 495px 1105px #fff, 882px 1448px #fff, 332px 1057px #fff, 614px 102px #fff, 192px 436px #fff,
    1215px 1164px #fff, 378px 1388px #fff, 426px 545px #fff, 114px 1488px #fff, 791px 428px #fff, 706px 677px #fff,
    862px 355px #fff, 1045px 1060px #fff, 1364px 964px #fff, 1204px 202px #fff, 1073px 1759px #fff, 346px 1178px #fff,
    972px 525px #fff, 319px 1208px #fff, 1535px 1603px #fff, 1185px 1129px #fff, 1282px 265px #fff, 152px 1010px #fff,
    660px 1871px #fff, 266px 1199px #fff, 367px 1083px #fff, 335px 1976px #fff, 1833px 924px #fff, 1026px 340px #fff,
    220px 28px #fff, 330px 101px #fff, 177px 598px #fff, 1699px 1883px #fff, 1169px 666px #fff, 1471px 92px #fff,
    369px 1220px #fff, 1464px 31px #fff, 944px 1339px #fff, 129px 57px #fff, 711px 1563px #fff, 734px 1295px #fff,
    376px 150px #fff, 97px 1745px #fff, 1940px 939px #fff, 7px 1495px #fff, 310px 267px #fff, 1252px 1719px #fff,
    1478px 1293px #fff, 1894px 286px #fff, 906px 1745px #fff, 474px 1591px #fff, 495px 1876px #fff, 802px 1684px #fff,
    162px 1667px #fff, 344px 551px #fff, 990px 1571px #fff, 1003px 1715px #fff, 1259px 874px #fff, 1115px 571px #fff,
    1597px 999px #fff, 1772px 1280px #fff, 1035px 1529px #fff, 792px 238px #fff, 1701px 769px #fff, 665px 993px #fff,
    457px 772px #fff, 597px 1550px #fff, 408px 59px #fff, 1086px 1672px #fff, 545px 1704px #fff, 956px 1314px #fff,
    1809px 1154px #fff, 1972px 769px #fff, 1841px 501px #fff, 1479px 395px #fff, 1880px 1827px #fff, 808px 1708px #fff,
    1714px 1166px #fff, 1765px 1139px #fff, 533px 115px #fff, 590px 1903px #fff, 384px 1311px #fff, 1389px 1702px #fff,
    1774px 1322px #fff, 1615px 1820px #fff, 44px 420px #fff, 349px 992px #fff, 1913px 1576px #fff, 1121px 1669px #fff,
    1340px 1327px #fff, 1648px 1236px #fff, 240px 1917px #fff, 1260px 1672px #fff, 604px 1192px #fff, 162px 1579px #fff,
    1164px 1652px #fff, 1235px 1509px #fff, 161px 100px #fff, 1645px 1587px #fff, 1757px 57px #fff, 405px 1055px #fff,
    343px 442px #fff, 651px 930px #fff, 1923px 1957px #fff, 1164px 753px #fff, 795px 252px #fff, 1013px 923px #fff,
    1835px 387px #fff, 1574px 1122px #fff, 1465px 1052px #fff, 1658px 1044px #fff, 990px 1708px #fff, 847px 433px #fff,
    1966px 743px #fff, 1331px 817px #fff, 367px 988px #fff, 561px 1560px #fff, 6px 604px #fff, 247px 1051px #fff,
    649px 1782px #fff, 1059px 1545px #fff, 1240px 800px #fff, 1035px 1933px #fff, 1005px 841px #fff, 1135px 1445px #fff,
    1091px 1815px #fff, 423px 1198px #fff, 567px 1768px #fff, 1147px 238px #fff, 156px 126px #fff, 1082px 641px #fff,
    522px 600px #fff, 187px 735px #fff, 1727px 237px #fff, 1546px 31px #fff, 1447px 182px #fff, 1906px 1300px #fff,
    1313px 1220px #fff, 1365px 560px #fff, 1821px 1424px #fff, 678px 459px #fff, 1017px 1948px #fff, 2000px 1178px #fff,
    601px 1368px #fff, 1720px 514px #fff, 156px 579px #fff, 527px 1994px #fff, 1540px 1719px #fff, 287px 1746px #fff,
    1999px 704px #fff, 822px 179px #fff, 171px 1747px #fff, 725px 250px #fff, 149px 1486px #fff, 883px 1769px #fff,
    1959px 1244px #fff;
}

@-webkit-keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
.astralbodyh {
  background: url(https://www.filterforge.com/filters/219.jpg),
    url(http://www.shedexpedition.com/wp-content/uploads/2014/05/K2-Mountain-2.jpg) no-repeat center center fixed;

  -webkit-background-size: cover, cover;
  background-size: cover, cover;

  -webkit-background-blend-mode: multiply, normal;
  background-blend-mode: multiply, normal;

  -webkit-animation: bgAnim 10s linear infinite;
  animation: bgAnim 10s linear infinite;
}

@keyframes bgAnim {
  from {
    background-position: 0px 0%, center center;
  }
  to {
    background-position: -512px 0%, center center;
  }
}

@-webkit-keyframes bgAnim {
  from {
    background-position: 0px 0%, center center;
  }
  to {
    background-position: -512px 0%, center center;
  }
}
.whiteout {
  color: white !important;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 250px;
  margin-top: 100px;
  input.someInp {
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    height: 36px;
    border-radius: 4px;
    padding-left: 15px;
    color: rgb(186, 186, 186);
    font-size: 19px;
    width: 300px;
    float: left;
    margin-right: 20px;
    &.alt {
      width: 100px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .wrap {
    width: auto;
  }
  #sigmagrind {
    display: none;
  }
  #circa {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .new-edito {
    margin-bottom: 100px;
  }
  .mainbuytext {
    width: 100% !important;
  }
  .topLane {
    margin-top: 10px;
  }
  .not-thin-wrap {
    width: 100%;
  }
  #leftEgg {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: none;
  }
  #rightEgg {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30px;
    #noticeBox {
      width: 100%;
    }
  }
  .pillowbox {
    width: 90% !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  #select-drop3 {
    width: 100% !important;
    background-position: 92% 15px;
  }
  .botlane {
    .reg-but- {
      width: 100% !important;
    }
  }
  #undervie {
    display: none;
  }
  #roady3 {
    width: 100% !important;
  }
  #registrybox {
    width: 89%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 20px 0 20px 0 !important;
    &.editedRoad {
      .reg-but- {
        width: 90%;
        margin-left: 5%;
      }
      .af-label-text {
        text-align: center;
        margin-top: 3px;
      }
    }
    &.privedit {
      width: 500;
      height: auto;
      min-height: 300;
    }
  }
  #noticeBox {
    width: 90%;
    margin-left: 5%;
  }
  #jumboimgz.altJumb {
    left: 0;
    #shipz {
      // z-index: -1;
      // width: 100%;
      display: none;
    }
    #moonz {
      // z-index: -1;
      // width: 100%;
      display: none;
    }
  }
  // grid-template-columns: 1fr !important;
  #foundergrid,
  #foundergrid2 {
    grid-template-columns: 1fr !important;
    width: 100% !important;
    height: auto !important;
  }
  .footright {
    display: none !important;
  }
  .aTwitterpic {
    margin-left: calc(50% - 40px);
    padding-bottom: 50px;
    margin-top: 30px;
  }
  #mainTimeline {
    width: 100%;
    position: relative;
  }
  .ageold.romanbutton {
    margin-top: 140px;
  }
  img#mainbar_1 {
    left: 10% !important;
    height: 1040px !important;
  }
  #sidebar_2,
  #sidebar_3 {
    margin-top: 410px !important;
  }
  #sidebar_4 {
    margin-top: 620px !important;
  }
  #sidebar_5 {
    margin-top: 830px !important;
  }
  #sidebar_1,
  #sidebar_2,
  #sidebar_3,
  #sidebar_4,
  #sidebar_5 {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    // margin-left: 0px !important;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .conta3 {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .tita3 {
    padding-top: 96px !important;
  }
  #cardinalRight {
    position: initial !important;
    float: none !important;
    width: 100% !important;
    height: auto !important;
    margin-top: 150px !important;
  }
  #oceanlike {
    height: auto !important;
    padding-bottom: 340px !important;
  }
  #circ_1,
  #circ_2,
  #circ_3,
  #circ_4 {
    text-align: center;
    height: 150px !important;
    // padding-bottom: 10px !important;
    // padding-top: 10px !important;
    position: initial !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 20px !important;
  }
  #circ_1_in,
  #circ_2_in,
  #circ_3_in,
  #circ_4_in,
  #circ_5_in {
    margin-top: 0px !important;
    width: 90% !important;
    text-align: center;
    vertical-align: center;
  }
  #cardinalTop {
    width: 100% !important;
  }
  #cardinalWrap {
    width: 100% !important;
    height: auto !important;
  }
  .cardinalMid {
    width: auto !important;
  }
  .romanbutton.fullkonmobile {
    width: 100% !important;
  }
  #cardinalLeft {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    float: none !important;
    padding-left: 0 !important;
  }
  #lightgrid {
    grid-template-columns: 1fr !important;
    width: 60%;
    height: auto !important;
  }
  #royalMidContain {
    width: 100%;
  }
  #adhdsec {
    height: auto !important;
  }
  .subBigSect {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  #royalMid {
    height: auto;
    padding-bottom: 50px !important;
  }
  #cynImg {
    display: none !important;
  }
  .crys-img {
    display: none;
  }
  .lf-side {
    height: auto !important;
    position: initial !important;
    margin-top: 0px !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    .romanbutton {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  .rt-side {
    position: initial !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    left: initial !important;
    .wall-img {
      margin-top: 30px !important;
      width: 100%;
      animation: none !important;
    }
  }
  #romanMid {
    padding-bottom: 40px !important;
  }
  #someImgHold {
    width: 100% !important;
    display: grid !important;
    grid-template-columns: 5fr 5fr;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  #romanTop {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  #jumboimgz {
    left: 72px;
  }
  #shipz {
    left: 20% !important;
    top: 620px;
    width: 50% !important;
    height: auto !important;
  }
  #moonz {
    z-index: 1 !important;
    top: 710px !important;
    left: calc(50% - 565px) !important;
  }
  .home-mint-us {
    margin-top: 0 !important;
  }
  h1.new-site-title {
    position: initial !important;
  }
  .home-join-us {
    margin-top: 38px !important;
  }
  .home-join-us,
  .new-site-title {
    position: initial !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    transform: none !important;
  }
  .joindisc {
    padding-top: 70px;
  }
  .home-mint-us {
    width: 100% !important;
    margin-top: 40px !important;
  }
  .jumbo {
    height: 1060px !important;
  }
  #bigztext {
    .maintxtare {
      font-size: 200px !important;
      margin-top: 100px !important;
    }
    img {
      width: 250px;
    }
    .subtxtare {
      font-size: 18px !important;
    }
  }

  .alefter {
    width: 100% !important;
    float: none !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    h1 {
      max-width: none !important;
      margin-left: 25px !important;
      margin-right: 25px !important;
      width: calc(100% - 50px) !important;
    }
    p {
      max-width: none !important;
      margin-left: 25px !important;
      margin-right: 25px !important;
      width: calc(100% - 50px) !important;
    }
  }
  .arighter {
    width: 100% !important;
    float: none !important;
    img {
      margin-top: 20px !important;
    }
  }
  img.joindisc {
    margin-left: auto;
    margin-right: auto;
    float: initial;
  }
  .arrow-container {
    display: none;
  }
  .slide-controler {
    right: 0px !important;
  }
  #watermark {
    display: none !important;
    // font-size: 75px !important;
    // bottom: -10px !important;
    // left: 13px !important;
  }
  .woedexwrap .dex-wrap {
    width: 90%;
    height: 200px !important;
  }
  .dex-cont {
    width: calc(100% - 80px) !important;
    margin-top: 170px;
  }
  h1.woedex-type {
    padding-bottom: 20px !important;
    line-height: 20px !important;
  }
  h1.woedex-name {
    font-size: 28px !important;
    padding-bottom: 31px !important;
  }
  #woe-image-capture {
    .main {
      top: 131px;
      left: 20%;
      width: 60% !important;
    }
    .gold {
      display: none;
    }
  }
  .woedex-disc {
    font-size: 11px !important;
    line-height: 17px;
  }
  .woedex-info {
    padding-bottom: 100px !important;
  }
  #roady .belly {
    margin-left: 5%;
    width: 90%;
  }
  .submaintext {
    margin-left: 5% !important;
    width: 90% !important;
    line-height: 23px !important;
    padding-bottom: 30px !important;
  }
  #menu-right {
    position: initial !important;
    float: left !important;
    margin-left: 5%;
    margin-top: 10px;
  }
  .mask,
  .mask2 {
    margin-right: 0 !important;
  }
  .mask2 {
    margin-right: 0px !important;
  }
  // cecil alain
  .mask,
  .mask2,
  .mask3 {
    border: none;
  }
  #sigmagrind {
    display: none;
  }
  p.notbig {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  #flipped {
    background-position: right bottom !important;
    #socialfeet img {
      margin-left: 4%;
      margin-right: 5%;
      margin-top: 50px !important;
    }
  }
  .footerMon {
    display: none;
  }
  #footer {
    height: auto !important;
  }
  .footleft {
    width: 100% !important;
    float: none !important;
    text-align: center !important;
    img {
      margin-left: 0 !important;
    }
  }
  .footleft a {
    float: none !important;
    img {
      float: none !important;
    }
  }
  #navigation #logo {
    width: 100%;
    text-align: left;
  }
  #lang-drop,
  #lang-drop2 {
    right: 27px;
    top: 23px;
  }
  .thetitle {
    margin-right: 45px;
  }
  .footright {
    width: 100% !important;
    float: none !important;
    text-align: center;
  }
  #flycatch {
    width: 100% !important;
  }
  #bluegrid {
    grid-template-columns: 1fr !important;
    grid-row-gap: 1em !important;
    .bluecap {
      padding-left: 5% !important;
      width: 85% !important;
      margin-left: 5% !important;
    }
  }
  #signup {
    margin-top: 70px;
    height: 320px !important;
    .inp-holder {
      width: 100%;
    }
    input.lefz {
      float: none;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      padding: 15px 5% 15px 5%;
    }
    .viewbutton.butedit_2 {
      margin-top: 27px;
      margin-left: 5%;
      float: left;
    }
  }
  .starlight {
    width: initial !important;
    height: auto !important;
  }
  #ablue {
    height: auto !important;
    width: initial;
    grid-template-columns: 1fr !important;
    padding-bottom: 20px;
    border-radius: 0;
    #crad {
      margin-top: 20px !important;
      .centa {
        width: auto;
        width: auto !important;
        margin-left: 5% !important;
        margin-right: 5% !important;
      }
    }
    #left_1 img {
      transform: rotate(0deg) !important;
      margin-top: -132px;
      width: 90%;
      margin-left: 5%;
    }
    h1.a1 {
      width: auto !important;
      margin-left: 5% !important;
      margin-right: 5% !important;
    }
    .a2 {
      width: auto !important;
      margin-left: 5% !important;
      margin-right: 5% !important;
    }
    .a3 {
      width: auto !important;
      margin-left: 5% !important;
      margin-right: 5% !important;
    }
  }
  #signGrid {
    grid-template-columns: 100fr 1fr 100fr !important;
    margin-top: -387px !important;
    font-size: 50px !important;
    text-align: center;
  }
  .non-mobile {
    display: none !important;
  }
  .moreBigText {
    height: auto !important;
    width: auto !important;
    margin-top: 0px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .moreMedText {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29px !important;
  }
  #classGrid {
    margin-left: 3%;
    margin-right: 3%;
    grid-template-columns: 1fr 1fr !important;
    grid-row-gap: 1em;
    grid-column-gap: 3em;

    margin-top: 50px !important;
    .oval {
      height: 100%;
      width: 100%;
      border-radius: 36%;
      img.back {
        margin-left: 0 !important;
        height: 100% !important;
        width: 100% !important;
      }
      .main {
        left: 0px !important;
        top: 5px !important;
        height: auto !important;
        width: 100% !important;
      }
      &.doubleoval {
        width: 60%;
        grid-column: span 2;
      }
    }
  }

  #lightgrad .lightclip {
    margin-top: 40px;
    width: auto;
    #lightgrid {
      height: auto !important;
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: 1fr !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      .shot:nth-of-type(1) {
        height: 200px !important;
      }
      .shot {
        img.tarrot {
          margin-left: 31px !important;
          margin-right: 10px !important;
        }
        .titlesec {
          left: 29px !important;
        }
        .subb {
          left: 29px !important;
        }
        .somecontain {
          p {
            width: auto;
          }
          h1 {
            margin-top: -10px;
          }
        }
      }
    }
  }
  .relativecontain {
    margin-top: 30px;
  }
  .midgrid {
    grid-template-columns: 1fr 1fr !important;
    padding-bottom: 30px;
    overflow: hidden;
    margin-top: 39px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
    .cell:nth-of-type(5) {
      // display: none !important;
      left: 50% !important;
    }
    .cell img {
      &.adjustmon {
        z-index: 999;
        overflow: hidden !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 100%;
      }
    }
  }
  #main-sect {
    margin-top: 20px;
  }
  #logo a img {
    width: 70%;
    margin-left: 5%;
  }
  #menu-right {
    position: absolute;
    top: -8px !important;
    right: 22px !important;
  }
  .thin-wrap {
    width: auto;
  }
  #homegrid {
    grid-template-columns: 1fr !important;
    grid-gap: 2em;
    .site-title {
      margin-top: 16px;
      margin-left: 5%;
      width: 90%;
    }
    .site-desc {
      margin-left: 5%;
      margin-top: 210px;
      width: 90%;
    }
    .button,
    .button-alt {
      float: none !important;
      margin-top: 25px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 235px !important;
    }
    .square-button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 52px;
      margin-bottom: 20px;
    }
  }
  .jumbo {
    height: auto;
    padding-bottom: 50px;
    .square-button {
      width: 90%;
    }
  }
  #midSection {
    margin-top: 20px;
    p.midinfo {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
